import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";
import { logOut } from "./authSlice";

export interface PackagesState {
    loading: boolean;
    allPackages: Models.PackageModel[];
    error: unknown;
}

const initialState: PackagesState = {
    loading: false,
    allPackages: [],
    error: null,
};

export const getPackages = createAsyncThunk(
    "packages/getPackages",
    async () => {
        return axiosInstance
            .get("Package/GetAllPackagesWithOffersAsync", {
                headers: {
                    Authorization: "Bearer ".concat(
                        localStorage.getItem("bearerToken") as string
                    ),
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
);

export const packagesSlice = createSlice({
    name: "packages",
    initialState,
    reducers: {
        clearPackages: (state) => {
            state = initialState;
        },
        deletePackage: (state, action) => {
            state.allPackages = state.allPackages.filter(
                (el: any) => el.uniqueId != action.payload
            );
        },
        addPackage: (state, action) => {
            state.allPackages.unshift({...action.payload, monthlyPrice:action.payload.price});
        },
        editPackage: (state, action) => {
            const foundIndex = state.allPackages.findIndex(
                (x: any) => x.uniqueId == action.payload.uniqueId
            );
            console.log(action.payload)
            const updatedPacket = {...state.allPackages[foundIndex], ...action.payload }
            state.allPackages[foundIndex] = updatedPacket;
        },
        addOfferToPackage:(state, action) => {
            const {offer, packageId} = action.payload;
            const foundIndex = state.allPackages.findIndex( 
                (x: any) => x.uniqueId == packageId
            );
            console.log(foundIndex)
            state.allPackages[foundIndex].offers = [offer, ...state.allPackages[foundIndex].offers];
        },
        removeOfferFromPackage:(state, action) =>{
            const {offerId, packageId} = action.payload;
            const foundIndex = state.allPackages.findIndex( 
                (x: any) => x.uniqueId == packageId
            );
            const updatedOffers = state.allPackages[foundIndex].offers.filter(el=>el.uniqueId !== offerId)
            state.allPackages[foundIndex].offers = updatedOffers;
        },
        editOfferOfPackage:(state, action) => {
            const {offerId, packageId, offer} = action.payload;
            const foundIndexPacket = state.allPackages.findIndex( 
                (x: any) => x.uniqueId == packageId
            );
            const foundIndexOffer = state.allPackages[foundIndexPacket].offers.findIndex( 
                (x: any) => x.uniqueId == offerId
            );
            const updatedOffer = {...state.allPackages[foundIndexPacket].offers[foundIndexOffer], ...offer} ;
            const updatedOffers = state.allPackages[foundIndexPacket].offers.map(el=>el.uniqueId === offerId ? updatedOffer : el); 

            state.allPackages[foundIndexPacket].offers = updatedOffers;
            console.log("updated",updatedOffers)
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getPackages.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPackages.fulfilled, (state, action) => {
            state.loading = false;
            state.allPackages = action.payload;
            state.error = "";
        });
        builder.addCase(getPackages.rejected, (state, action) => {
            state.loading = false;
            state.allPackages = [];
            state.error = action.error;
        });
        builder.addCase(logOut, (state) => {
            state.allPackages = [];
            state.error = "";
            state.loading = false;
        });
    },
});

export const { clearPackages, deletePackage, addPackage, editPackage, addOfferToPackage, removeOfferFromPackage, editOfferOfPackage } =
    packagesSlice.actions;
export default packagesSlice.reducer;
