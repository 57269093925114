import { Dialog } from "@mui/material";
import EditPackageContent from "Components/AdminComponents/PackagesComponents/EditPackageContent";
import { useNavigate } from "react-router";

const EditPackage = ()=>{

    const navigate = useNavigate();

    return <Dialog open={true} onClose={()=>navigate('/admin/packages')} fullWidth={true} maxWidth="sm">
        <EditPackageContent/>
    </Dialog>
}

export default EditPackage;