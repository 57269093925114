import { Dialog } from "@mui/material";
import AddPackageContent from "Components/AdminComponents/PackagesComponents/AddPackageContent"
import { useNavigate } from "react-router";

const AddPackage = ()=>{

    const navigate = useNavigate();

    return <Dialog open={true} onClose={()=>navigate('/admin/packages')} fullWidth={true} maxWidth="sm">
        <AddPackageContent/>
    </Dialog>
}

export default AddPackage;