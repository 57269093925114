import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCarsAsync } from "Redux/slices/carsSlice";
import { useAppSelector } from "Redux/store";
import { Stack, Paper, Box, Avatar, Typography, Button } from "@mui/material";
import * as Models from "Models/AdminModels";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import loadingGif from 'Assets/images/garageLoading.gif';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';



const CarsList: FC = () => {
  const dispatch = useDispatch<any>();
  const allCars = useAppSelector((state) => state.cars.allCars);
  const allCarsFetching = useAppSelector((state) => state.cars);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCarsAsync());
  }, []);


  const generateContent = (fetch:any) => {
    if(allCarsFetching.loading){
      return <Box sx={{img:{width:'100%'}}}><img src={loadingGif} alt="loading" /></Box>
    } else if (fetch.allCars.length > 0){
      return       <Grid container spacing={2}>
      {allCars?.map((car) => (
        <Grid xs={12} md={6} lg={4}>
          {" "}
          <SingleCar car={car} key={car.id} />{" "}
        </Grid>
      ))}
    </Grid>
    } else {
      return <p>garage is empty</p>
    }
  }

  return (
    <div>
      <Button color="success" variant="contained" sx={{margin:'40px 0 20px 0'}} onClick={()=>navigate('addMake')}>დაამატეთ მწარმოებელი</Button>
{generateContent(allCarsFetching)}
    </div>
  );
};

export default CarsList;

const SingleCar: FC<{ car: Models.CarsRes }> = ({ car }) => {
  const navigate = useNavigate();

  const onDelete = () => {
    navigate(`deleteMake/${car.id}`);
  };

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ opacity: "0.7" }}
      >
        <Button
          onClick={onDelete}
          color="error"
          variant="contained"
          disableElevation={true}
          sx={{
            transform: "rotate(-45deg) translate(-22px, -26px)",
            width: "91px",
          }}
        >
          <RemoveCircleIcon sx={{ transform: "rotate(45deg)" }} fontSize="small" />
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => navigate(`editMake/${car.id}`)}
          disableElevation={true}
          sx={{
            transform: "rotate(45deg) translate(22px, -26px)",
            width: "91px",
          }}
        >
          <EditIcon fontSize="small" sx={{ transform: "rotate(-45deg)" }} />
        </Button>
      </Stack>
      <Stack
        spacing={1}
        justifyContent="center"
        sx={{ padding: "10px", marginBottom: "10px" }}
      >
        <Box>
          {" "}
          <Avatar
            alt="Remy Sharp"
            src={car.imageUrl}
            sx={{ width: 70, height: 70, margin: "10px auto" }}
          />
        </Box>
        <Typography
          sx={{ fontSize: "20px", color: "#121212" }}
          textAlign="center"
        >
          {car.title}
        </Typography>
      </Stack>
      <Button
        onClick={() => navigate(`makeModels/${car.id}`)}
        disableElevation={true}
        sx={{
          width: "100%",
          background: "#b6848450",
          borderRadius: 0,
          color: "#000000a4",
          fontSize: "14px",
        }}
      >
        view models
      </Button>
    </Paper>
  );
};
