import Paper from '@mui/material/Paper';
import React, { useRef, useState } from 'react';
import {Button, Box, Stack} from '@mui/material';
import TextField from '@mui/material/TextField';
import useAxiosPrivateInstance from 'Hooks/useAxiosPrivateInstance';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addTranslation, setSearchValue } from 'Redux/slices/translationsSlice';

type AddTranslationProps = {
    
};

const AddTranslation:React.FC<AddTranslationProps> = () => {
    

    const [openAdding, setOpenAdding] = useState(false)
    const [adding, setAdding] = useState(false);
    const [geoInput, setGeoInput] = useState('');
    const [engInput, setEngInput] = useState('');

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useDispatch();

    const addTranslationHandler = async () => {
        setAdding(true)
        await axiosPrivateInstance.post('Translation/AddAsync', {geo:geoInput, eng:engInput})
        .then(res=>{
            toast.success(res.data);
            dispatch(addTranslation(res.data))
            setGeoInput('')
            setEngInput('')
        })
        .catch(err=>toast.error(err))
        .finally(()=>setAdding(false))
        
    }

    const cancelAddingHandler = () => {
        setOpenAdding(false)
        setGeoInput('')
        setEngInput('')
    }

    return <Paper sx={{padding:'20px'}} component={Stack} spacing={2}>
        <Stack direction={"row"}><SearchInput/><Button variant="contained" sx={{width:'fit-content', marginLeft:'auto'}} onClick={()=>setOpenAdding(true)}>+ დაამატე ტარგმანი</Button></Stack>
        {openAdding && <Paper sx={{padding:'10px'}} elevation={4}>
        <Box sx={{ width: '100%' }} component={Stack} direction="row" spacing={4}>
                <TextField value={geoInput} multiline={true} fullWidth onChange={(e) => setGeoInput(e.target.value)} rows={4} sx={{ backgroundColor: '#87cefa44' }} label="ქართული"/>
                <TextField value={engInput} multiline={true} fullWidth onChange={(e) => setEngInput(e.target.value)} rows={4} sx={{ backgroundColor: '#87cefa44' }} label="english"/>
            </Box>
            <Stack sx={{ width:'fit-content', margin:'20px 0', marginLeft:'auto'}} direction="row" spacing={2}>
            <Button variant="contained" color="success" onClick={addTranslationHandler} disabled={adding}>შენახვა</Button>
            <Button variant="contained" color="error" onClick={cancelAddingHandler}>გაუქმება</Button>
            </Stack>
        </Paper>}
        </Paper>
}
export default AddTranslation;


const SearchInput: React.FC = () => {
    
    
    const dispatch = useDispatch();
    const timeoutRef = useRef<number | null>(null);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
      
          timeoutRef.current = window.setTimeout(() => {
            dispatch(setSearchValue(event.target.value));
            console.log(event.target.value)
          }, 200);
      
    };
  

  
    return (
        <TextField
          label="მოძებნე თარგმანი"
          onChange={handleChange}
          size="small"
          fullWidth
          sx={{ maxWidth:'300px'}}
        />
    );
  };

  
  
  
  