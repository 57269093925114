import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Login, ResetPassword, Forbidden } from "Pages/GenericPages";

import * as AdminPages from "Pages/AdminPages";

import Requirements from "Security/Requirements/Requirements";
import ProtectedRoute from "../Security/ProtectedRoute/ProtectedRoute";
import MainRouteSwitch from "../Security/MainRouteSwitch/MainRouteSwitch";
import LoggedOutRoute from "../Security/LoggedOutRoute/LoggedOutRoute";
import { AdminContainer } from "Containers";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainRouteSwitch />} />
        <Route path="/login" element={<LoggedOutRoute component={Login} />} />
        <Route
          path="/resetPassword"
          element={<LoggedOutRoute component={ResetPassword} />}
        />
        <Route path="/forbidden" element={<Forbidden />} />
        {/* admin pages */}
        <Route path="/admin">
          <Route
            index
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Dashboard />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          />
          <Route
            path="partnersAllOffers"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer
                    component={<AdminPages.PartnersAllOffers />}
                  />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          >
            <Route
              path="addOfferForPartner"
              element={<AdminPages.AddOfferForPartner />}
            />
            <Route path=":id" element={<AdminPages.EditOfferForPartner />} />
          </Route>
          <Route
            path="packages"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Packages />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          >
            <Route path="addPackage" element={<AdminPages.AddPackage />} />
            <Route
              path="editPackage/:id"
              element={<AdminPages.EditPackage />}
            />
            <Route
              path="addOfferToPackage/:id"
              element={<AdminPages.AddOfferToPackage />}
            />
            <Route
              path="editOfferOfPackage/:packageId/:offerId"
              element={<AdminPages.EditOfferOfPackage />}
            />
          </Route>
          translations
          <Route
            path="translations"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.TranslationsPage />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          />
          {/* <Route
            path="gifts"
            element={
              <ProtectedRoute
                jsxElement={<AdminContainer component={<AdminPages.Gifts />} />}
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          /> */}
          <Route
            path="waitings"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Waitings />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          />
          <Route
            path="expenses"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Expenses />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          >
            <Route path="addExpense" element={<AdminPages.AddExpenses />} />
            <Route path=":id" element={<AdminPages.EditExpenseCategories />} />
          </Route>
          <Route
            path="advices"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Advices />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          />
          <Route
            path="cars"
            element={
              <ProtectedRoute
                jsxElement={
                  <AdminContainer component={<AdminPages.Garage />} />
                }
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          >
            <Route
              path="deleteMake/:id"
              element={<AdminPages.GarageModal componentName="deleteMake" />}
            />
            <Route
              path="makeModels/:id"
              element={<AdminPages.GarageModal componentName="makeModels" />}
            />
            <Route
              path="editMake/:id"
              element={<AdminPages.GarageModal componentName="editMake" />}
            />
            <Route
              path="addMake"
              element={<AdminPages.GarageModal componentName="addMake" />}
            />
          </Route>

          {/* FAQ  */}

          <Route
            path="faq"
            element={
              <ProtectedRoute
                jsxElement={<AdminContainer component={<AdminPages.FAQ />} />}
                requirements={[Requirements.MustBeAdmin]}
              />
            }
          >
            <Route path="deleteMake/:id" element={<AdminPages.GarageModal componentName="deleteMake" />} />
            <Route path="makeModels/:id" element={<AdminPages.GarageModal componentName="makeModels" />} />
            <Route path="editMake/:id" element={<AdminPages.GarageModal componentName="editMake" />} />
            <Route path="addMake" element={<AdminPages.GarageModal componentName="addMake" />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
