import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import Stack from "@mui/material/Stack";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { CostumCheckbox, CostumTextField } from "Components/AdminComponents/Shared/CostumInputs";
import { useEditPartnerOffer } from "../hooks";
import FileUpload from "Components/Generals/FileUpload/FileUpload";


const InputSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  image: yup.string(),
  location: yup.string().required(),
});

interface IInputs {
  title: string;
  description: string;
  image: File | string | undefined;
  imageUrl: string,
  location: string;
}

const EditOfferForPartner: React.FC = () => {
  const navigate = useNavigate();
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const [offerImage, setOfferImage] = useState<File>();

  const handleClose = () => {
    console.log("handle");
    navigate("/admin/partnersAllOffers");
  };
  const { id } = useParams();


  useEffect(() => {
    axiosPrivateInstance
      .get(`Offer/GetOfferByIdAsync/${id}`)
      .then((res) => {
        reset({
          title: res.data.title,
          description: res.data.description,
          image: res.data.imageUrl,
          location: res.data.location
        });
      })
      .catch((err) => toast.error(err))
      .finally(() => {
        console.log("fetching all offers finished");
      });
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IInputs>({
    resolver: yupResolver(InputSchema)
  });


  const [loading, editPartnerOfferAsync] = useEditPartnerOffer();

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    //console.log("data", data) image: "[object File]" just for hook form errors.
    editPartnerOfferAsync({
      ...data,
      offerId: id,
      image: offerImage,
    })
  };
  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <Paper
        sx={{
          padding: "20px",
          width: '100%',
          maxWidth: 500,
          margin: '20px auto',
        }}
      >
        <Typography
          sx={{
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          edit userOffer{" "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <CostumTextField name={"title"} label={"title"} control={control} InputLabelProps={{
              shrink: watch("title") ? true : false,
            }} />
            <CostumTextField name={"description"} label={"description"} control={control}
              InputLabelProps={{
                shrink: watch("description") ? true : false,
              }}
            />
            <FileUpload name={"image"} control={control} accept="image" setFormValue={setValue} handleFile={setOfferImage} />
            <CostumTextField name={"location"} label={"location"} control={control}
              InputLabelProps={{
                shrink: watch("location") ? true : false,
              }}
            />
            <Button variant="contained" type="submit" disabled={loading}>
              save
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
};

export default EditOfferForPartner;


