import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Models from "Models/AdminModels";
import { axiosInstance } from "Axios/axios";
import { logOut } from "./authSlice";

export interface PackagesState {
    loading: boolean;
    singleCar: Models.CarsRes | null;
    error: unknown;
}

const initialState: PackagesState = {
    loading: false,
    singleCar: null,
    error: null,
};

export const getSingleCarThunk = createAsyncThunk(
    "cars/getSingleCar",
    async (id: number) => {
        return axiosInstance
            .get(`/Dealership/GetMakeByIdAsync/${id}`, {
                headers: {
                    Authorization: "Bearer ".concat(
                        localStorage.getItem("bearerToken") as string
                    ),
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
);

export const carsSlice = createSlice({
    name: "cars",
    initialState,
    reducers: {
        clearSingleCar: (state) => {
            state.singleCar = null;
        },
        addModels: (state, action) => {
            console.log(action.payload);
            if (state.singleCar !== null) {
                state.singleCar.models.unshift(...action.payload);
            }
        },
        removeModel: (state, action) => {
            if (state.singleCar !== null) {
                state.singleCar.models = state.singleCar.models.filter(
                    (el) => el.id !== action.payload
                );
            }
        },

        editModel: (state, action) => {
            if (state.singleCar !== null) {
                const index = state.singleCar.models.findIndex(
                    (el) => el.id == action.payload.id
                );
                state.singleCar.models[index] = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSingleCarThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSingleCarThunk.fulfilled, (state, action) => {
            state.loading = false;
            state.singleCar = action.payload;
            state.error = "";
        });
        builder.addCase(getSingleCarThunk.rejected, (state, action) => {
            state.loading = false;
            state.singleCar = null;
            state.error = action.error;
        });
        builder.addCase(logOut, (state) => {
            state.singleCar = null;
            state.error = "";
            state.loading = false;
        });
    },
});

export const { addModels, removeModel, clearSingleCar, editModel } =
    carsSlice.actions;
export default carsSlice.reducer;
