import { AddTranslation, TranslationsList } from 'Components/AdminComponents/TranslationsComponents';
import React from 'react';
import {Paper, Typography} from '@mui/material';

type TranslationsPageProps = {
    
};

const TranslationsPage:React.FC<TranslationsPageProps> = () => {
    
    return <Paper sx={{width:'100%'}}>
        <Typography sx={{color:'rgb(35, 77, 161)', padding:'20px 10px 0 20px', fontSize:'20px', fontWeight:'bolder'}}>თარგმნები</Typography>
        <AddTranslation/>
        <TranslationsList/>
        </Paper>
}
export default TranslationsPage;