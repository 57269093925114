import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { Controller, UseFormSetValue } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const AcceptFileTypes = {
  Image: "image/jpeg, image/jpg, image/png",
  File: ".doc, .docx, .pdf",
};

type FileUploadProps = {
  name: string;
  control: any;
  accept: "image" | "file";
  setFormValue: UseFormSetValue<any>;
  handleFile: (file: File) => void;
  clearErrors?: any;
};

const FileUpload: FC<FileUploadProps> = ({
  name,
  control,
  accept,
  setFormValue,
  handleFile,
  clearErrors,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files![0];
    setFile(file);

    const previewUrl = URL.createObjectURL(file);

    setImagePreviewUrl(previewUrl);

    setFormValue(name, file); // This will just set "[object File]" string to name field its needed to remove hook form error.
    // clearErrors(name);
    handleFile(file);
  };

  return (
    <FileUploadStyled>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <TextField
            fullWidth
            error={error ? true : false}
            {...(error
              ? accept === "image"
                ? { helperText: "სურათის ატვირთვა სავალდებულოა." }
                : { helperText: "ფაილის ატვირთვა სავალდებულოა." }
              : null)}
            disabled={true}
            label={accept === "image" ? "სურათის ატვირთვა" : "ფაილის ატვირთვა"}
            value={
              file
                ? file.name
                : field.value
                ? accept === "image"
                  ? "მიმაგრებული სურათი"
                  : "მიმაგრებული ფაილი"
                : accept === "image"
                ? "აირჩიეთ სურათი"
                : "აირჩიეთ ფაილი"
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {imagePreviewUrl ? (
                    <img
                      className="previewImage"
                      src={imagePreviewUrl}
                      alt="Preview"
                    />
                  ) : field.value ? (
                    <img
                      className="previewImage"
                      src={field.value}
                      alt="Current"
                    />
                  ) : (
                    <InsertDriveFileIcon />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <Button
                      variant="outlined"
                      startIcon={<PhotoCamera />}
                      component="label"
                      style={{ textTransform: "none" }}
                    >
                      {accept === "image" ? "სურათის არჩევა" : "ფაილის არჩევა"}
                      <input
                        hidden
                        type="file"
                        accept={
                          accept === "image"
                            ? AcceptFileTypes.Image
                            : AcceptFileTypes.File
                        }
                        name={field.name}
                        ref={field.ref}
                        onChange={handleFileChange}
                        onBlur={field.onBlur}
                      />
                    </Button>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        )}
      />
    </FileUploadStyled>
  );
};

export default FileUpload;

const FileUploadStyled = styled.div`
  .Mui-disabled.MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.6);
  }
  .Mui-disabled.Mui-error.MuiInputLabel-root {
    color: #d32f2f;
  }
  .Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d32f2f;
  }

  &:hover {
    .Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgba(0, 0, 0, 0.87) !important;
    }
  }
  .previewImage {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;
