import React from "react";

import { Checkbox, FormControl, InputLabelProps, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

interface ICostumTextField {
  name: string;
  label: string;
  control: any;
  InputLabelProps?:Partial<InputLabelProps> | undefined;
  size?:"small" | "medium" | undefined;
  type?:string;
}

const CostumTextField: React.FC<ICostumTextField> = ({
  name,
  label,
  control,
  InputLabelProps,
  size,
  type
}) => {
  return (
    <Controller
      control={control}
      name={name}
      
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
            label={label}
            {...field}
            variant="outlined"
            error={!!error}
            InputLabelProps={InputLabelProps}
            size={size}
            type={type ? type : 'text'}
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

interface ICostumCheckbox {
  label: string;
  name: string;
  control: any;
}

const CostumCheckbox: React.FC<ICostumCheckbox> = ({
  label,
  name,
  control,
}) => {
  return (
    <section>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field:{value, ...rest} }) => <Checkbox {...rest} checked={value}/>}
      />
    </section>
  );
};

export { CostumTextField, CostumCheckbox };
