import { axiosInstance } from "Axios/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Models from "Models";
import { logIn, getUser } from "Redux/slices/authSlice";

export const useLogin = () => {
    const dispatch: any = useDispatch();
    const [serverErrors, setServerErrors] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const loginAsync = async (data: any) => {
        setLoading(true);

        axiosInstance
            .post<Models.LoginResponse>(`Account/LoginAsync`, data)
            .then((response) => {
                dispatch(logIn(response.data));
                dispatch(getUser());
            })
            .catch((error) => {
                toast.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return { loginAsync, loading, serverErrors };
};
