import React, { FC, useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";


import { useAssignOffers, useEditOptions, useFetchOffers } from "./hooks";
import * as Models from "Models";
import { SubmitHandler, useForm } from "react-hook-form";
import Stack from "@mui/system/Stack/Stack";
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import Input from "@mui/material/Input";
import { useDispatch } from "react-redux";
import { editOfferOfPackage } from "Redux/slices/packagesSlice";

type EditOfferOfPackageContentProps = {
    offerId:string;
    packageId:string
};
interface IOfferInput {
    isUsageLimited?: boolean;
    useCount?: number;
    discountPercent?: number;
    offerId?:string;
    title?:string;
    uniqueId?:string;
  }
const EditOfferOfPackageContent:React.FC<EditOfferOfPackageContentProps> = ({offerId, packageId}) => {
    
    const [offer, setOffer] = useState<IOfferInput>();
    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useDispatch();

    


    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm<IOfferInput>();
  const [loading, reqError, editOptionsAsync] = useEditOptions();
  const onSubmit: SubmitHandler<IOfferInput> = data => {
    let useCount = !data.isUsageLimited ? 0 : Number(data.useCount);
    let discountPercent =!data.discountPercent ? 0 : Number(data.discountPercent);
    let isUsageLimited = data.isUsageLimited ? true : false;
    console.log(typeof useCount)
    if(packageId !== null){
        editOptionsAsync({isUsageLimited, offerId, useCount, discountPercent, packageId})
    }
    
  };
  useEffect(()=>{
    axiosPrivateInstance.get(`Package/GetPackageByIdAsync/${packageId}`)
    .then(res=>{
        const offerToEdit = res.data.offers.find((x:IOfferInput) => x.uniqueId === offerId);
        setOffer(offerToEdit)
        const {isUsageLimited, useCount, discountPercent} = offerToEdit;
        reset({isUsageLimited, useCount, discountPercent})
        console.log({isUsageLimited, useCount, discountPercent})

        
        console.log("Dasd",offerId, packageId, offerToEdit)   
        
    })
    .catch(err=>
        toast.error(err))
},[])

  
  return <Paper sx={{padding:'40px'}}>
    <Typography sx={{color:'blue', marginBottom:'15px'}} textAlign="center">{offer?.title}</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} sx={{maxWidth:'300px', margin:'auto'}}>
        <FormControlLabel sx={{padding:'10px 15px', }} control={<Input sx={{marginRight:'10px', width:'15px', height:'15px', borderBottom:'none'}} type="checkbox"  {...register("isUsageLimited")} />} label="ლიმიტირებული გამოყენება"></FormControlLabel>
      {watch('isUsageLimited') &&<TextField defaultValue="test" type="number" {...register("useCount")} size="small"  label="რაოდენობა" InputLabelProps={{ shrink: true }}/>}
      <TextField defaultValue="test" type="number" {...register("discountPercent")} size="small"  label="პროცენტი" InputLabelProps={{ shrink: true  }}/>
    <Button type="submit" variant="contained" sx={{marginTop:'10px'}} disabled={!!loading}>რედაქტირება</Button>
    </Stack>
    </form>
  </Paper>
}
export default EditOfferOfPackageContent;