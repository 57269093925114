import { FC, useEffect, useRef, useState } from "react";
import { Stack, Paper, Typography, CircularProgress, IconButton, Button, Fade  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRemoveOrEditModel } from "./hooks";
import TextField from "@mui/material/TextField";
import EditIcon from '@mui/icons-material/Edit';


interface IModel {
    id: number;
    title: string;
}

const CarModels:FC<{models:IModel[], makeId:number}> = ({models, makeId}) => {
  return <Stack>
  <Typography sx={{ fontSize: '16px', fontWeight:'bolder' }}>Models:</Typography>
  <Stack sx={{margin:'10px 0'}}>
      {models ? models.map((model: any) => <SingleModel model={model} key={model.id} makeId={makeId}/>) : <Typography>there is no car models</Typography>}
  </Stack>
</Stack>
}

export default CarModels;





const SingleModel: FC<{makeId:number, model: IModel }> = ({ makeId, model }) => {

const [loading, loadingEdit, removeModelAsync, editModelAsync] = useRemoveOrEditModel();

const ref = useRef<any>();

const [title, setTitle] = useState(model.title)

const [editing, setEditing] = useState(false);

const onSave = () => {
  editModelAsync({title, modelId:model.id, makeId}).then(()=>setEditing(false))
  
}

const onEditClick = () => {  
  setEditing(true);
  console.log(ref.current)
}

useEffect(() => {
  if(editing){
    ref.current.focus()
  }
}, [editing]);

const onDelete = () => {
    removeModelAsync(model.id)
}
    return <Paper sx={{padding:'5px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
{editing ? <Fade in={true}><Stack
sx={{
  flexDirection:'row'
}}
><TextField
value={title}
onChange={(e)=>setTitle(e.target.value)}
size="small"
placeholder="enter title"
inputRef={ref}
/>
<Button onClick={onSave} disabled={!!loadingEdit}>save</Button>
</Stack></Fade> : <Typography> {model.title}</Typography>}
       

{<Stack direction={"row"}><IconButton onClick={onEditClick} disableRipple={true} sx={{height:'40px', width:'40px', '&:hover':{color:'red'}}}>
          <EditIcon />
      </IconButton>
        <IconButton onClick={onDelete} disableRipple={true} sx={{height:'40px', width:'40px', '&:hover':{color:'red'}}}>
        {loading ? (
          <CircularProgress size={"1rem"} />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>
      </Stack>}
    </Paper>

}