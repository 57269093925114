import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTranslation, editTranslation, getTranslationsAsyncThunk } from 'Redux/slices/translationsSlice';

import { useAppSelector } from 'Redux/store';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import useAxiosPrivateInstance from 'Hooks/useAxiosPrivateInstance';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
type TranslationsListProps = {

};

const TranslationsList: React.FC<TranslationsListProps> = () => {


    const { translations, loading, error, searchValue } = useAppSelector(state => state.translations)

    const dispatch = useDispatch<any>();
    useEffect(() => {
        dispatch(getTranslationsAsyncThunk())
    }, [])

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <Paper component={Stack} spacing={4} sx={{ padding: '20px' }}>{translations?.filter(item=>JSON.stringify(item).includes(searchValue)).map(el => <SingleTranslation key={el.id} {...el} />)}</Paper>


}

export default TranslationsList;


interface ISingleTranslation {
    id: number;
    geo: string;
    eng: string;
}

const SingleTranslation: FC<ISingleTranslation> = (props) => {
    const { id, geo, eng } = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [saving, setSving] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)



    const [geoInput, setGeoInput] = useState(geo);
    const [engInput, setEngInput] = useState(eng);

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useDispatch();


    const cancelEditHandler = () => {
        setIsEditing(false)
        setGeoInput(geo)
        setEngInput(eng)
    }

    const saveTranslationHandler = async () => {
        setSving(true)
        await axiosPrivateInstance.post('Translation/EditAsync', { id, geo: geoInput, eng: engInput })
            .then(res => {
                dispatch(editTranslation(res.data));
                toast.success('edited');
                setIsEditing(false)
            })
            .catch(err => console.log(err))
            .finally(() => setSving(false))
    }
    
    const deleteTranslateHandler = async () => {
        
        setDeleting(true)
        await axiosPrivateInstance.delete(`Translation/DeleteByIdAsync/?id=${id}`)
            .then(res => {
                dispatch(deleteTranslation(id))
                toast.success('წარმატებით წაიშალა');
                setIsEditing(false)
            })
            .catch(err => toast.error(err.toString()))
            .finally(() => setDeleting(false))
    }

    return <Paper component={Stack} direction="row" spacing={4} sx={{ padding: '10px' }}>
        {!isEditing ? <Box sx={{ width: '100%' }} component={Stack} direction="row" spacing={4}>
            <Paper sx={{ width: '50%', padding: '10px', position:'relative' }}><Typography>{geo}</Typography><Box sx={{position:'absolute', left:'5px', bottom:'5px', opacity:'0.5',width:'20px',img:{objectFit:'cover', width:'100%'}}}><img width="32" src="https://www.worldometers.info/img/flags/small/tn_gg-flag.gif" alt="" /></Box></Paper>
            <Paper sx={{ width: '50%', padding: '10px', position:'relative' }}><Typography>{eng}</Typography><Box sx={{position:'absolute', left:'5px', bottom:'5px', opacity:'0.5',width:'20px',img:{objectFit:'cover', width:'100%'}}}> <img width="32" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="" /></Box></Paper>
        </Box>
            : <Box sx={{ width: '100%' }} component={Stack} direction="row" spacing={4}>
                <TextField value={geoInput} multiline={true} fullWidth onChange={(e) => setGeoInput(e.target.value)} rows={4} sx={{ backgroundColor: '#87cefa44' }} />
                <TextField value={engInput} multiline={true} fullWidth onChange={(e) => setEngInput(e.target.value)} rows={4} sx={{ backgroundColor: '#87cefa44' }} />
            </Box>}
        <Stack spacing={2}>
            {isEditing ? <IconButton aria-label="cancel-edit" size="small" onClick={cancelEditHandler}>
                <DoDisturbOnIcon fontSize="inherit" />
            </IconButton> : <IconButton aria-label="edit" size="small" onClick={() => setIsEditing(state => !state)}>
                <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>}
            <IconButton aria-label="delete" size="small" disabled={deleting} >
            {deleting ? (
                    <CircularProgress size={20} />
                ) : (
                    <DeleteIcon fontSize="inherit" onClick={deleteTranslateHandler} color="error"/>
                )}
                
            </IconButton>
            <IconButton aria-label="delete" size="small" disabled={saving || (geo===geoInput && eng===engInput)} >
                {saving ? (
                    <CircularProgress size={20} />
                ) : (
                    <SaveIcon fontSize="inherit" onClick={saveTranslationHandler} color="success" sx={{ opacity:(saving || (geo===geoInput && eng===engInput)) ? 0.5 : 1 }}/>
                )}
            </IconButton>

        </Stack>
    </Paper>

}