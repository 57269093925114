import React, { useEffect, useState } from "react";
import { Container } from "../../../../Pages/AdminPages/PartnersAllOffers/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/store";
import {
  deleteOfferForPartner,
  getPartnersOffers,
} from "Redux/slices/partnersAllOffersSlice";
import * as Models from "Models/AdminModels";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { Avatar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { toast } from "react-toastify";

const PartnersAllOffersList: React.FC = () => {
  const dispatch = useDispatch<any>();
  const allPartnersOffersWithFetchState = useSelector(
    (state: RootState) => state.partnersAllOffers
  );
  const navigate = useNavigate();
  const onAddUserOffer = () => {
    navigate("addOfferForPartner");
  };
  useEffect(() => {
    dispatch(getPartnersOffers());
  }, []);

  const [partnerId, setPartnerId] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setPartnerId(event.target.value);
  };
  console.log(allPartnersOffersWithFetchState);

  return (
    <Container>
      <Paper sx={{ marginBottom: "20px" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={partnerId}
            onChange={handleChange}
            displayEmpty
            disabled={allPartnersOffersWithFetchState.loading}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {allPartnersOffersWithFetchState.partnersAllOffers?.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.firstName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>filter by partner</FormHelperText>
        </FormControl>
      </Paper>

      <Button
        variant="contained"
        onClick={onAddUserOffer}
        sx={{ marginBottom: "10px" }}
      >
        add offer
      </Button>

      {
        <Stack spacing={8}>
          {allPartnersOffersWithFetchState.loading
            ? [1, 2, 3].map((el: number) => (
                <Skeleton
                  key={el}
                  variant="rectangular"
                  width={"100%"}
                  height={100}
                />
              ))
            : allPartnersOffersWithFetchState.partnersAllOffers
                ?.filter((el) => el.id !== partnerId)
                .map((item) => (
                  <Stack direction="column" key={item.id}>
                    <Paper>
                      <Stack direction="row" p={2}>
                        <Box width="100%">
                          <Typography>first name: {item.firstName}</Typography>
                          <Typography>last name: {item.lastName}</Typography>
                          <Typography>location: tbilisi</Typography>
                        </Box>
                        <Avatar
                          alt="Remy Sharp"
                          src=""
                          sx={{ width: 56, height: 56 }}
                        />
                      </Stack>
                      <Stack direction="column" p={2} spacing={2}>
                        {item.offers?.map((el) => (
                          <SingleUserOffer
                            el={el}
                            partnerId={item.id}
                            key={item.id}
                          />
                        ))}
                      </Stack>
                    </Paper>
                  </Stack>
                ))}
        </Stack>
      }

      <Outlet />
    </Container>
  );
};

export default PartnersAllOffersList;

const SingleUserOffer: React.FC<{ el: Models.Offer; partnerId: string }> = ({
  el,
  partnerId,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const onDelete = () => {
    setLoading(true);
    axiosPrivateInstance
      .get(`Offer/DeleteOfferByIdAsync/${el.id}`)
      .then((res) => {
        toast.success("ოფერი წაშლილია");
        dispatch(
          deleteOfferForPartner({ offerId: el.id, partnerId: partnerId })
        );
      })
      .catch((er) => console.log(er))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper sx={{ padding: "20px", position: "relative" }} key={el.id}>
      <Avatar
        alt="offer image"
        src={el.imageUrl}
        sx={{ width: 56, height: 56 }}
      />
      <Typography component={"h4"}>{el.title}</Typography>
      <Typography>description: {el.description}</Typography>
      {!el.isApplicableToAssignToPackage && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "5px 10px",
            border: "1px solid #87CEEB",
            color: "#4682B4",
            width: "fit-content",
            borderRadius: "10px",
          }}
        >
          <LocalOfferIcon sx={{ fontSize: 17 }} />
          extra offer
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          right: 0,
          top: 0,
          alignItems: "center",
          height: "50px",
          width: "80px",
          justifyContent: "spacec-between",
        }}
      >
        <IconButton onClick={() => navigate(`${el.id}`)} disableRipple={true}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete} disableRipple={true}>
          {loading ? <CircularProgress size={"1rem"} /> : <DeleteIcon />}
        </IconButton>
      </Box>
    </Paper>
  );
};
