import { useAppDispatch } from "./../../../Redux/store";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react";
import { toast } from "react-toastify";
import { addOfferForPartner, editOfferForPartner } from "Redux/slices/partnersAllOffersSlice";
import { useNavigate } from "react-router-dom";

interface IPartnerOffer {
    isApplicableToAssignToPackage: boolean;
    title: string;
    description: string;
    image: File | undefined;
    location: string;
    discountPercent: number;
    partnerId: string;
    userIds: string;
    useCount: number;
    isUsageLimited: boolean;
}

const useAddOffer = (): [boolean, string, typeof addOfferAsync] => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const dispatch = useAppDispatch();
    const axiosPrivateInstance = useAxiosPrivateInstance();
    const navigate = useNavigate();

    const addOfferAsync = (data: IPartnerOffer) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.set(key, value);
        }
        axiosPrivateInstance
            .post("Admin/AddOfferAsync", formData)
            .then((res) => {
                dispatch(addOfferForPartner(res.data));
                toast.success("offer added");
                navigate("/admin/partnersAllOffers");
            })
            .catch((er) => {
                console.log(er);
                toast.error("oops something went wrong");
            })
            .finally(() => {
                // reset();
                setLoading(false);
            });
    };

    return [loading, error, addOfferAsync];
};

interface IEditOfferData {
    title: string;
    description: string;
    image: File | string | undefined;
    location: string;
    offerId: string | undefined;
}

const useEditPartnerOffer = (): [boolean, typeof editPartnerOfferAsync] => {
    const [loading, setLoading] = useState(false);

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();

    const editPartnerOfferAsync = async (data: IEditOfferData) => {
        //console.log(data);
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.set(key, value);
        }
        // for (const value of formData.entries()) {
        //     console.log(value);
        // }
        axiosPrivateInstance
            .post("Offer/EditOfferAsync", formData)
            .then((res) => {
                dispatch(editOfferForPartner({ offer: res.data, partnerId: res.data.partnerId }));
                toast.success("Offer Edited");
            })
            .catch((er) => {
                console.log(er);
                toast.error("oops something went wrong");
            })
            .finally(() => {
                // reset();
                setLoading(false);
            });
    };

    return [loading, editPartnerOfferAsync];
};



interface IUserPhoneNumber {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber:string;
}


const useGetUserByPhoneNumber = (): [
    boolean,
    string,
    IUserPhoneNumber[],
    (phoneNumber: string) => Promise<void>,
    (id: string) => void
] => {
    const axiosInstance = useAxiosPrivateInstance();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [users, setUsers] = useState<IUserPhoneNumber[]>([]);

    const fetchUser = async (phoneNumber: string) => {
        setLoading(true);
        setError("");
        await axiosInstance
            .get(`Account/GetUserByPhoneNumberAsync/${phoneNumber}`)
            .then((res) => setUsers((state) => [...state, res.data]))
            .catch((err) => setError(err.message))
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteUser = (id: string) => {
        setUsers((state) => state.filter((el) => el.id !== id));
    };

    return [loading, error, users, fetchUser, deleteUser];
};



export { useAddOffer, useEditPartnerOffer, useGetUserByPhoneNumber };
