import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
  Stack,
  Dialog,
  TextField,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddExpenseCategory } from "../hooks";
import {
  CostumCheckbox,
  CostumTextField,
} from "Components/AdminComponents/Shared/CostumInputs";

import FileUpload from "Components/Generals/FileUpload/FileUpload";

const InputSchema = yup.object({
  title: yup.string().required(),
  image: yup.string().required(),
  pattern: yup.string().required(),
  color: yup.string().required(),
});

interface IOfferDetails {
  title: string;
  pattern: string;
  image: File | undefined;
  color: string;
}

const AddExpensesCategory: React.FC = () => {
  const navigate = useNavigate();

  const [offerImage, setOfferImage] = useState<File>();

  const handleClose = () => {
    navigate("/admin/expenses");
  };

  const { control, handleSubmit, watch, setValue, clearErrors } =
    useForm<IOfferDetails>({
      resolver: yupResolver(InputSchema),
      defaultValues: {
        title: "",
        pattern: "",
        color: "",
      },
    });

  const [loading, error, addExpenseCategoryAsync] = useAddExpenseCategory();
  const onSubmit: SubmitHandler<IOfferDetails> = (data) => {
    addExpenseCategoryAsync({
      ...data,
      image: offerImage,
    });
  };
  console.log(watch());

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <Typography
        sx={{
          textAlign: "center",
          margin: "40px 0 10px ",
          fontWeight: "bolder",
        }}
      >
        add expense category for partner
      </Typography>
      <Stack
        sx={{
          margin: "40px auto",
        }}
        spacing={3}
      >
        <Paper
          sx={{
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                margin: "20px 10px",
                textAlign: "center",
              }}
            >
              კატეგორიის დეტალები
            </Typography>
            <Stack spacing={3}>
              <CostumTextField label="title" name="title" control={control} />
              <FileUpload
                name={"image"}
                control={control}
                accept="image"
                setFormValue={setValue}
                handleFile={setOfferImage}
                clearErrors={clearErrors}
              />
              <CostumTextField
                label="pattern"
                name="pattern"
                control={control}
              />
              <CostumTextField
                label="color"
                name="color"
                control={control}
                type="color"
                InputLabelProps={{
                  shrink: true,
                }}
              ></CostumTextField>

              <Button variant="contained" type="submit" disabled={loading}>
                add
              </Button>
            </Stack>
          </form>
        </Paper>
      </Stack>
    </Dialog>
  );
};

export default AddExpensesCategory;
