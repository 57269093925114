import { FC } from "react";
import { useParams } from "react-router-dom";
import AddCarModels from "./AddCarModel";
import CarModels from "./CarModels";
import * as Models from 'Models/AdminModels';
import CircularProgress from '@mui/material/CircularProgress';

const MakeModelsContent:FC<{carMake:Models.CarsRes | null, loading:boolean}> = ({carMake, loading}) => {


    const {id} = useParams();
    console.log(typeof id === 'string')
    if(loading){
      return <CircularProgress/>
    } else {
    return ( <div>
       {(typeof id === 'string') &&
       <>
    {carMake !== null && <CarModels makeId={carMake.id} models={carMake.models}/>}
    <AddCarModels makeId={Number(id)}/> 
    </> }
    </div> );
    }
}
 
export default MakeModelsContent;