import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

export const Container = styled(Paper)`
  box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
  border-radius: 24px;
  width: 100%;
  max-width: 924px;
  box-sizing: border-box;
  padding: 32px;
  .container__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:28px;
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #326fe6;
  
  }

`;