import { FC, useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Box, Stack, Paper, Typography, Button } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import { useAddModels } from "./hooks";

interface IAddModelProps {
    makeId:number;
}

interface AddModelInputs {
    title:string;
}

const AddCarModels:FC<IAddModelProps> = ({makeId}) => {

    const {control, handleSubmit, reset} = useForm<AddModelInputs>();
    const [modelNames, setModelNames] = useState<string[]>([])
    
    const [loading, error, addModelsAsync] = useAddModels();

    const onSubmit: SubmitHandler<AddModelInputs> = data => {
       setModelNames(state=>[...state, data.title]);
       reset()
     };

    const deleteSelectedModel = (ind:number) => {
        setModelNames(state=>state.filter((el,index)=>index != ind))
    } 

    const handleSaveModels = () => {
        addModelsAsync(makeId, modelNames).then(res=>setModelNames([]))
    }

   return ( <Box sx={{margin:'20px 0'}}>
    <Typography sx={{ fontSize: '16px', fontWeight:'bolder', marginBottom:'10px' }}>add modedls:</Typography>
       <form onSubmit={handleSubmit(onSubmit)}>
       <Controller
        name="title"
        control={control}
        defaultValue=""
       
        render={({ field }) => <TextField {...field} size={"small"}  placeholder="enter model"  InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" type="submit">
                  <AddBoxIcon sx={{color:'green'}}/>
                </IconButton>
              </InputAdornment>
            ),
          }}/>}
      />
      <Stack>
          
            {modelNames.map((el, index)=><Paper key={index} sx={{padding:'5px', justifyContent:"space-between", flexDirection:"row"}} component={Stack}>
            <Typography>{el}</Typography>
            <CloseIcon sx={{cursor:'pointer'}} onClick={()=>deleteSelectedModel(index)}/>
            </Paper>)}
            {(modelNames.length > 0) && <Button variant="contained" onClick={handleSaveModels} disabled={loading}>save models</Button>}
      </Stack>
        </form></Box>
   )
}

export default AddCarModels;