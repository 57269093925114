import { FC } from "react";
import { Link } from "react-router-dom";
import { Container, FooterLayout, Terms, Subscribe, FooterSection } from './styles';
import footerLogo from '../../Assets/images/Logo-New.svg';
import { Button } from "@mui/material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import appleImage from 'Assets/images/Apple.svg';
import googleImage from 'Assets/images/Google.svg';


const Footer: FC = () => {
    return (<Container>
        <FooterLayout>
            <FooterSection>
                <h3> <div className="footer__logo">
                    <img src={footerLogo} style={{width: 50}}alt="" />
                </div></h3>
                <nav>
                    <Link to="#">ჩვენს შესახებ</Link>
                </nav>
            </FooterSection>
            <FooterSection>
                <h3>საკონტაქტო ინფორმაცია</h3>
                <nav>
                    <Link to="/#"><PhoneAndroidIcon />557-71-81-81</Link>
                    <Link to="/#"><MailIcon />info@autocircle.app</Link>
                </nav>
            </FooterSection>

            <FooterSection>
                <h3>გადმოწერე აპლიკაცია</h3>
                <nav style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <Link to=" "><img src={googleImage} alt="" /> </Link>
                    <Link to=" "><img src={appleImage} alt="" /></Link>
                </nav>
            </FooterSection>
        </FooterLayout>
    </Container>
    )
}

export default Footer;

