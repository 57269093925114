import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
  Stack,
  Dialog,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useAddOffer,
} from "../hooks";
import {
  CostumCheckbox,
  CostumTextField,
} from "Components/AdminComponents/Shared/CostumInputs";
import AssignPartner from "./AssignPartner";
import AssignUsers from "./AssignUsers";
import FileUpload from "Components/Generals/FileUpload/FileUpload";

const InputSchema = yup.object({
  isApplicableToAssignToPackage: yup.boolean().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  image: yup.string().required(),
  location: yup.string().required(),
  discountPercent: yup.number(),
  useCount: yup.number(),
  isUsageLimited: yup.boolean()
});

interface IOfferDetails {
  isApplicableToAssignToPackage: boolean;
  title: string;
  description: string;
  image: File | undefined;
  location: string;
  discountPercent: number;
  useCount: number;
  isUsageLimited: boolean;
}

const AddOfferForPartner: React.FC = () => {
  const navigate = useNavigate();

  const [userIds, setUserIds] = useState<string[]>([]);
  const [partnerId, setPartnerId] = useState<string>("");
  const [offerImage, setOfferImage] = useState<File>();

  const handleClose = () => {
    navigate("/admin/partnersAllOffers");
  };

  const handleUserIds = (ids: string[]) => {
    setUserIds(ids); // array to string line "guid,guid,guid"
  };

  const handlePartnerId = (id: string) => {
    setPartnerId(id);
    if (id) {
      setPartnerReady(true)
    } else {
      setPartnerReady(false)
    }
  };

  const [partnerReady, setPartnerReady] = useState(true)

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm<IOfferDetails>({
    resolver: yupResolver(InputSchema),
    defaultValues: {
      isApplicableToAssignToPackage: true,
      isUsageLimited: true,
      useCount: 0,
      discountPercent: 0
    },
  });


  useEffect(() => {
    if (watch("isApplicableToAssignToPackage")) {
      setUserIds([])
    }
  }, [watch("isApplicableToAssignToPackage")])



  const [loading, error, addOfferAsync] = useAddOffer();
  const onSubmit: SubmitHandler<IOfferDetails> = (data) => {
    partnerId ? addOfferAsync({
      ...data,
      partnerId,
      userIds: userIds.toString().length < 1 ? "0" : userIds.toString(),
      image: offerImage,
    }) : setPartnerReady(false);
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <Typography
        sx={{
          textAlign: "center",
          margin: '40px 0 10px ',
          fontWeight: 'bolder'
        }}
      >
        add offer for partner{" "}
      </Typography>
      <Stack
        sx={{
          margin: "40px auto",
        }}
        spacing={3}
      >
        {!watch("isApplicableToAssignToPackage") && <AssignUsers setUserIds={handleUserIds} />}
        <AssignPartner setPartnerId={handlePartnerId} />
        {!partnerReady && <Typography sx={{ fontSize: '13px', color: 'red', marginTop: '5px !important' }}>you must choose partner</Typography>}
        <Paper
          sx={{
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                margin: "20px 10px",
                textAlign: "center",
              }}
            >
              შეთავაზების დეტალები
            </Typography>
            <Stack spacing={3}>
              <CostumCheckbox
                label="პაკეტის შეთავაზება"
                name="isApplicableToAssignToPackage"
                control={control}
              />
              <CostumTextField label="title" name="title" control={control} />
              <FileUpload name={"image"} control={control} accept="image" setFormValue={setValue} handleFile={setOfferImage} clearErrors={clearErrors} />
              <CostumTextField
                label="აღწერა"
                name="description"
                control={control}
              />
              <CostumTextField
                label="მდებარეობა"
                name="location"
                control={control}
              />
              {!watch('isApplicableToAssignToPackage') && <>
                <CostumCheckbox
                  label="ლიმიტირებული გამოყენება"
                  name="isUsageLimited"
                  control={control}
                />
                {watch('isUsageLimited') && <CostumTextField
                  label="რაოდენობა"
                  name="useCount"
                  control={control}
                  type="number"
                />}
                <CostumTextField
                  label="ფასდაკლების პროცენტი"
                  name={"discountPercent"}
                  control={control}
                  type="number"
                />

              </>}

              <Button variant="contained" type="submit" disabled={loading}>
                add
              </Button>
            </Stack>
          </form>
        </Paper>
      </Stack>
    </Dialog>
  );
};

export default AddOfferForPartner;
