import { Button, Dialog, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCarBrand } from "Redux/slices/carsSlice";
import { useAppDispatch } from "Redux/store";

const DeleteMakeWarning = () => {

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    const handleClose = () => {
        navigate('/admin/cars')
    }   
    
    const [loading, setLoading] =  useState(false)
    

    const removeMakeAsync = async () => {
        setLoading(true)
        axiosPrivateInstance.get(`Dealership/RemoveMakeByIdAsync/${id}`)
        .then((res:any)=>{
            toast.success('მწარმოებელი წაშლილია')
            dispatch(deleteCarBrand(id))
            handleClose()
            })
            .catch((err:any)=>toast.error("oops something went wrong"))
            .finally(()=>{
                setLoading(false)
            })
    }


  return <> <Box sx={{padding:'20px'}}>
        <Typography textAlign={'center'}>დარწმუნებული ხართ რომ მწარმოებლის წაშლა გსურთ? გაფრთხილებთ რომ მასთან ერთად წაიშლება ყველა მოდელი</Typography>
        <Stack sx={{flexDirection:'row', justifyContent:'center',margin:'10px 0', gap:'30px'}}><Button onClick={()=>removeMakeAsync()} disabled={!!loading} color="error" variant="contained">წაშლა</Button>
        <Button onClick={handleClose} variant="contained">გაუქმება</Button>
        </Stack>
  </Box></>
}

export default DeleteMakeWarning