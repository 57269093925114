import React from 'react'
import { Container, Name } from './style'
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logOut } from 'Redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { Link } from 'react-router-dom';

const Header = () => {
  const { role } = useSelector((state: RootState) => state.auth.userInfo)
  return (
    <Container>
      <SearchIcon sx={{ color: '#869AB8' }} />
      <Badge color="secondary" badgeContent={4} sx={{ span: { backgroundColor: '#FF316F' } }}>
        <NotificationsIcon sx={{ color: '#326FE6' }} />
      </Badge>

      <BasicMenu />
      {role == "admin" && <Button variant="contained" component={Link} to="/admin">admin</Button>}
    </Container>
  )
}

export default Header;


const BasicMenu = () => {
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector((state: RootState) => state.auth.userInfo)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (): void => {
    dispatch(logOut())
  }
  return (
    <div>

      <Name id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}><span>{firstName ? firstName + " " + lastName : "unknown"}</span><KeyboardArrowDownIcon /></Name>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} component={Link} to={"/user-profile"}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

    </div>
  );
}