import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 450px;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  margin: auto;
  margin-bottom:100px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
  line-height: 39px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #326FE6;
    margin-top: 90px;
    margin-bottom: 100px;
  }

  form {

    max-width: 350px;
    padding: 20px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing:border-box;
    
    .forget-password {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #326fe6;
      text-decoration: none;
    }
    .submit-btn {
      padding: 19.5px 24px;
      background: #326FE6;
      border-radius: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.9;
      }
    }
    .signUp {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #869ab8;
      a{
        color:#326FE6
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;


  label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: #10002e;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;

    background: #ffffff;
    border: 1px solid #d5d4dc;
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.2px;
    color: #8b849b;
  }

  .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left:5px;
  }
`;
