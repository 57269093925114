import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/store";
import { useAppDispatch } from "./../../../Redux/store";
import { getCategories } from "Redux/slices/expenseSlice";
import * as Models from "Models";
import { deleteExpenseCategories } from "Redux/slices/expenseSlice";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { Avatar } from "@mui/material";

import { SelectChangeEvent } from "@mui/material/Select";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { toast } from "react-toastify";

const ExpensesList: React.FC = () => {
  const dispatch = useDispatch<any>();
  const categories = useSelector(
    (state: RootState) => state.expenseCategories.allCategories
  );
  console.log(categories);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const navigate = useNavigate();
  const onAddUserOffer = () => {
    navigate("addExpense");
  };
  console.log(categories);

  return (
    <Container>
      <Button
        variant="contained"
        onClick={onAddUserOffer}
        sx={{ marginBottom: "10px" }}
      >
        add Expense Category
      </Button>

      {
        <Stack spacing={8}>
          {categories == undefined
            ? [1, 2, 3].map((el: number) => (
                <Skeleton
                  key={el}
                  variant="rectangular"
                  width={"100%"}
                  height={100}
                />
              ))
            : categories.map((item) => {
                return <SingleCategorie item={item}></SingleCategorie>;
              })}
        </Stack>
      }

      <Outlet />
    </Container>
  );
};

export default ExpensesList;

const SingleCategorie: React.FC<{ item: Models.GetCategoriesResponse }> = ({
  item,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useAppDispatch();

  const onDelete = () => {
    setLoading(true);
    axiosPrivateInstance
      .delete(`Expense/DeleteCategoryByIdAsync/${item.id}`)
      .then((res) => {
        console.log(item.id);

        dispatch(deleteExpenseCategories(item.id));
        toast.success("კატეგორია წაშლილია");
      })
      .catch((er) => console.log(er))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper sx={{ padding: "20px", position: "relative" }} key={item.id}>
      <Stack direction="row" p={2}>
        <Avatar
          alt="Remy Sharp"
          src={item.imageUrl}
          sx={{ width: 56, height: 56 }}
        />
      </Stack>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          right: 0,
          top: 0,
          alignItems: "center",
          height: "50px",
          width: "80px",
          justifyContent: "spacec-between",
        }}
      >
        <IconButton onClick={() => navigate(`${item.id}`)} disableRipple={true}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete} disableRipple={true}>
          {loading ? <CircularProgress size={"1rem"} /> : <DeleteIcon />}
        </IconButton>
      </Box>
      <Typography component={"h4"}>{item.title}</Typography>
      <Typography>{item.pattern}</Typography>
    </Paper>
  );
};
