import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Stack from "@mui/material/Stack";

import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useEditPackage } from "./hooks";

export const InputSchema = yup.object({
  title: yup.string().required(),
  price: yup.number().required(),

});

interface IPackage {
  title: string;
  price: number;
}

const EditPackageContent: React.FC = () => {
  const { id } = useParams();
  
  const axiosPrivateInstance = useAxiosPrivateInstance();
  
  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<IPackage>({
    resolver: yupResolver(InputSchema),
  });

  const [loading, reqError, editPackageAsync] = useEditPackage();

  const onSubmit: SubmitHandler<IPackage> = (data) => {
    editPackageAsync(data, id);
  };

  useEffect(() => {
    axiosPrivateInstance
      .get<any>(`Package/GetPackageByIdAsync/${id}`)
      .then((res: any) => {
        const { title, price } = res.data;
        reset({
          title,
          price
        });
      });
  }, []);

  return (
    <Paper
      sx={{
        padding: "20px",
        margin:'40px auto',
        maxWidth:'300px'
      }}
    >
      <Typography
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontWeight: "bolder",
        }}
      >
        პაკეტის რედაქტირება{" "}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <TextField
            id="title"
            placeholder="enter name"
            label="title"
            fullWidth
            margin="dense"
            {...register("title")}
            error={errors.title ? true : false}
            InputLabelProps={{
              shrink: watch("title") ? true : false,
            }}
          />

          <TextField
            type="number"
            id="price"
            placeholder="enter name"
            label="price"
            fullWidth
            margin="dense"
            {...register("price")}
            error={errors.price ? true : false}
            InputLabelProps={{
              shrink: !isNaN(watch("price")) ? true : false,
            }}
          />

       

          <Button variant="contained" type="submit" disabled={loading}>
          save
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default EditPackageContent;