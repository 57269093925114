import React, { FC, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";


import { useAssignOffers, useFetchOffers } from "./hooks";
import * as Models from "Models";
import { SubmitHandler, useForm } from "react-hook-form";
import Stack from "@mui/system/Stack/Stack";
import CloseIcon from '@mui/icons-material/Close';


interface IOfferInput {
  isUsageLimited?: boolean;
  useCount?: number;
  discountPercent?: number;
  offerId?:string;
  title?:string;
}

const AddOfferToPackage: React.FC = () => {
  const [offers, setOffers, offersLoading] = useFetchOffers();
 
  const [selectedSingleOffer, setSelectedSingleOffer] = useState<Models.OfferModel | null>(null); 
  const {id:packageId} = useParams()
  const selectOffer = (data: Models.OfferModel) => {
    setOpen(false);
    setSelectedSingleOffer(data)

  };

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();



  // package id

  return (<Dialog open={true} onClose={()=>navigate('/admin/packages')} fullWidth={true} maxWidth="sm">
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        boxSizing: "border-box",
        padding: "20px",
        minHeight: "400px",
        maxWidth: "400px",
        width:'100%',
        margin: "40px auto",
      }}
    >
        <CloseIcon sx={{position:'absolute', right:'10px', top:'10px', cursor:'pointer'}} onClick={()=>navigate('/admin/packages')}/>
      <Typography
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontWeight: "bolder",
        }}
      >
        შეთავაზებების შეცვლა{" "}
      </Typography>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={offers}
        loading={offersLoading}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            onClick={() => selectOffer(option)}
            key={option.uniqueId}
          >
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Asynchronous"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {offersLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
  {selectedSingleOffer && <Paper sx={{padding:'5px'}}>
        <Typography mb={1} sx={{fontWeight:'bold'}}>დაამატეთ შეთავააზება: </Typography>
      <Stack sx={{height:'100%', flex:1, position:'relative'}} spacing={1}>
      <CloseIcon sx={{position:'absolute', right:'2px', top:'2px', cursor:'pointer', '&:hover':{color:'red'}}} onClick={()=>{setSelectedSingleOffer(null)}}/>
        <SingleOfferForm offer={{title:selectedSingleOffer?.title, offerId:selectedSingleOffer.uniqueId}} packageId={packageId}/>
      </Stack>
      </Paper>}

    

    </Paper>
    </Dialog>
  );
};

export default AddOfferToPackage;



const SingleOfferForm:FC<{offer:IOfferInput, packageId:string | undefined}> = ({offer, packageId}) => {

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm<IOfferInput>();
  const [assignLoading, reqError, assignOfferAsync] = useAssignOffers();
  const onSubmit: SubmitHandler<IOfferInput> = data => {
    let useCount = !data.isUsageLimited ? 0 : Number(data.useCount);
    let discountPercent =!data.discountPercent ? 0 : Number(data.discountPercent)
    console.log(typeof useCount)
    if(packageId !== null){
      assignOfferAsync({offer:{...offer, ...data, useCount, discountPercent}, packageId})
    }
    console.log({packageId,offer:{...data, discountPercent, useCount}})
  };

  
  return <Paper sx={{padding:'10px'}}>
    <Typography sx={{color:'blue'}}>{offer.title}</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1}>
        <FormControlLabel control={<Checkbox defaultChecked={false} {...register("isUsageLimited")} />} label="ლიმიტირებული გამოყენება"></FormControlLabel>
      {watch('isUsageLimited') &&<TextField defaultValue="test" type="number" {...register("useCount")} size="small" placeholder="რაოდენობა"/>}
      <TextField defaultValue="test" type="number" {...register("discountPercent")} size="small" placeholder="პასდაკლების პროცენტი"/>
    <Button type="submit" variant="contained" sx={{marginTop:'10px'}} disabled={!!assignLoading}>დამატება</Button>
    </Stack>
    </form>
  </Paper>

}
