import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {
  addExpenseCategorie,
  editExpenseCategorie,
} from "Redux/slices/expenseSlice";
import { useAppDispatch } from "./../../../Redux/store";

interface IPartnerOffer {
  title: string;
  pattern: string;
  image: File | undefined;
  color: string;
  id?: string;
}

const useAddExpenseCategory = (): [boolean, string, typeof addExpenseAsync] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const navigate = useNavigate();

  const addExpenseAsync = (data: IPartnerOffer) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value);
    }

    axiosPrivateInstance
      .post("Expense/AddCategoryAsync", formData)
      .then((res) => {
        dispatch(addExpenseCategorie(res.data));
        navigate("/admin/expenses");
        toast.success("expense category added");
      })
      .catch((er) => {
        toast.error("oops something went wrong");
      })
      .finally(() => {
        // reset();
        setLoading(false);
      });
  };

  return [loading, error, addExpenseAsync];
};

interface IEditCategoryData {
  title: string;
  pattern: string;
  image: File | string | undefined;
  id: number;
  color: string;
}

const useEditExpenseCategorie = (): [boolean, typeof editCategoryAsync] => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const axiosPrivateInstance = useAxiosPrivateInstance();

  const editCategoryAsync = async (data: IEditCategoryData) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value);
    }
    console.log(data);
    axiosPrivateInstance
      .post("Expense/EditCategoryByIdAsync", formData)
      .then((res) => {
        dispatch(editExpenseCategorie(res.data));
        navigate("/admin/expenses");
        toast.success("Category  Edited");
      })
      .catch((er) => {
        console.log(er);
        toast.error("oops something went wrong");
      })
      .finally(() => {
        // reset();
        setLoading(false);
      });
  };

  return [loading, editCategoryAsync];
};

// interface IUserPhoneNumber {
//     id: string;
//     firstName: string;
//     lastName: string;
//     phoneNumber:string;
// }

// const useGetUserByPhoneNumber = (): [
//     boolean,
//     string,
//     IUserPhoneNumber[],
//     (phoneNumber: string) => Promise<void>,
//     (id: string) => void
// ] => {
//     const axiosInstance = useAxiosPrivateInstance();

//     const [loading, setLoading] = useState<boolean>(false);
//     const [error, setError] = useState<string>("");
//     const [users, setUsers] = useState<IUserPhoneNumber[]>([]);

//     const fetchUser = async (phoneNumber: string) => {
//         setLoading(true);
//         setError("");
//         await axiosInstance
//             .get(`Account/GetUserByPhoneNumberAsync/${phoneNumber}`)
//             .then((res) => setUsers((state) => [...state, res.data]))
//             .catch((err) => setError(err.message))
//             .finally(() => {
//                 setLoading(false);
//             });
//     };

//     const deleteUser = (id: string) => {
//         setUsers((state) => state.filter((el) => el.id !== id));
//     };

//     return [loading, error, users, fetchUser, deleteUser];
// };

export { useAddExpenseCategory, useEditExpenseCategorie };
