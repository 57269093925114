import { FC, useState, useEffect } from "react";
// import { useGetUser } from "Hooks/useGetUser";

const Dashboard: FC = () => {
    // const { getUserAsync } = useGetUser();
    // getUserAsync();

    return (
        <div>admin dashboard</div>
    )
}

export default Dashboard;