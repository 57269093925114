import { Dialog } from "@mui/material";
import EditOfferOfPackageContent from "Components/AdminComponents/PackagesComponents/EditOfferOfPackageContent";
import { useNavigate, useParams } from "react-router";
import CloseIcon from '@mui/icons-material/Close';


const EditOfferOfPackage = ()=>{

    const navigate = useNavigate();
    const { packageId, offerId } = useParams();
    return <Dialog open={true} onClose={()=>navigate('/admin/packages')} fullWidth={true} maxWidth="sm">
         <CloseIcon sx={{position:'absolute', right:'10px', top:'10px', cursor:'pointer'}} onClick={()=>navigate('/admin/packages')}/>
        <EditOfferOfPackageContent packageId={typeof packageId === 'undefined' ? '' : packageId} offerId={typeof offerId === 'undefined' ? '' : offerId}/>
    </Dialog>
}

export default EditOfferOfPackage;