import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePackage, getPackages, removeOfferFromPackage } from "Redux/slices/packagesSlice";
import { RootState } from "Redux/store";

import { Container } from "./styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from "react-toastify";


interface IOffer {
  discountPercent: number;
  imageUrl: string;
  isUsageLimited: string;
  title: string;
  uniqueId: string;
  useCount: number;

}

const Packages: React.FC = () => {

  const packagesDataWithFetchState = useSelector((state: RootState) => state.packages)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (localStorage.getItem('bearerToken')) {
      dispatch(getPackages())
    }
  }, [])

  useEffect(() => {
  }, [packagesDataWithFetchState])

  const navigate = useNavigate();
  const onAddPackage = () => {
    navigate("addPackage");
  };

  console.log(packagesDataWithFetchState);

  return (
    <Container>
      <Stack direction="column" spacing={2} sx={{ marginBottom: '30px' }}>
        {packagesDataWithFetchState.loading
          ? [1, 2, 3].map((el: number) => (
            <Skeleton
              key={el}
              variant="rectangular"
              width={'100%'}
              height={50}
            />
          ))
          :
          !packagesDataWithFetchState.loading ? packagesDataWithFetchState.allPackages.map(
            (element: any) => (
              <SinglePackage key={element.uniqueId} packet={element} />
            )
          ) : null}
      </Stack>
      <Button variant="contained" onClick={onAddPackage}>
        დაამატე პაკეტი
      </Button>

      <Outlet />
    </Container>
  );
};

export default Packages;


const SinglePackage: React.FC<{ packet: any }> = ({ packet }) => {

  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>()
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const onDelete = () => {
    setDeleting(true)
    axiosPrivateInstance.get(`Package/RemovePackageById/${packet.uniqueId}`)
      .then(res => {
        dispatch(deletePackage(packet.uniqueId))

      })
      .catch(er => console.log(er))
      .finally(() => {
        setDeleting(false)
      })
  };


  const [expanded, setExpanded] = React.useState<boolean | undefined>(false);

  const handleChange = (e: React.SyntheticEvent) => {
    setExpanded(state => !state)
  }


  const addOfferHandler = (offer: IOffer) => {

    console.log('add offer handler')
  }



  return <Accordion expanded={!!expanded} onChange={handleChange}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Typography sx={{ width: '33%', flexShrink: 0 }}>
        {packet.title}
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>ფასი:{packet.monthlyPrice}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Stack spacing={3}>
        <Paper sx={{ padding: '10px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <Button variant="contained" color={'secondary'} onClick={() => navigate(`editPackage/${packet.uniqueId}`)}>პაკეტის რედაქტირება</Button>
          <Button variant="contained" color={'error'} onClick={onDelete} disabled={!!deleting}>{deleting ? 'იშლება' : 'პაკეტის წაშლა'}</Button>
          <Button variant="contained" sx={{ marginLeft: 'auto' }} onClick={() => navigate(`addOfferToPackage/${packet.uniqueId}`)}>+ დაამატე შეთავაზება</Button>
        </Paper>
        {packet.offers?.length !== 0 && packet.offers?.map((offer: any) => <SingleOffer offer={offer} key={offer.uniqueId} packageId={packet.uniqueId} />)}
      </Stack>
    </AccordionDetails>
  </Accordion>
}

// single offer

const SingleOffer: React.FC<{ offer: any, packageId: string }> = ({ offer, packageId }) => {

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const [deleting, setDeleting] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteOfferFromPackage = async (packageId: string, offerId: string) => {
    setDeleting(true)
    axiosPrivateInstance.post('Package/UnassignOfferFromPackageAsync', { packageId, offerId })
      .then(res => {
        dispatch(removeOfferFromPackage({offerId, packageId}))
        toast.success('offer deleted')
      })
      .catch(err => toast.error(err))
      .finally(() => setDeleting(false))
    console.log({ packageId, offerId })
  }

  const openEdit = ()=>{
    navigate(`editOfferOfPackage/${packageId}/${offer.uniqueId}`);

  }

  return <Paper sx={{ padding: '10px 5px', position: 'relative' }} elevation={3}>
    <Box sx={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}>
      <EditIcon sx={{ '&:hover': { color: '#9c27b0' } }} onClick={openEdit}/>
      <DeleteIcon sx={{ '&:hover': { color: 'red' } }} onClick={() => deleteOfferFromPackage(packageId, offer.uniqueId)} />
    </Box>

    <Typography sx={{ color: 'blue', marginBottom: '10px' }}>{offer.title}</Typography>
    <Typography sx={{ span: { fontWeight: '700' } }}><span>გამოყენების რაოდენობა:</span> {!offer.isUsageLimited ? 'ულიმიტო გამოყენება' : offer.useCount}</Typography>
    <Typography sx={{ span: { fontWeight: '700' } }}><span>ფასდაკლების პროცენტი:</span> {offer.discountPercent || 'მითითებული არ არის'}</Typography>
  </Paper>

}


