import { FC } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";

import { Container, InputContainer } from "./styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "../../../Hooks/useLogin";
import * as Models from "Models";

type LoginFields = {
  phoneNumber: number,
  password: string,
}

const phoneRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const LoginSchema = yup
  .object({
    phoneNumber: yup.string().matches(phoneRegEx, 'Phone number is not valid').required(),
    password: yup.string().required(),

  })
  .required();

export const Login: FC = () => {
  const { loading, serverErrors, loginAsync } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFields>({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = (data: LoginFields) => {
    loginAsync(data);
  };

  return (
    <>
      <Header />
      <Container>
        <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}>მოგესალმებით 🤖</h1>
        <h2>გაიარეთ ავტორიზაცია</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <label>მობილური ნომერი</label>
            <input
              {...register("phoneNumber")}
              type="text"
              placeholder="შეიყვანეთ ნომერი"
            />
            {errors.phoneNumber && (
              <p className="error-msg">{errors.phoneNumber.message}</p>
            )}
          </InputContainer>
          <InputContainer>
            <label>პაროლი</label>
            <input
              {...register("password")}
              type="password"
              placeholder="შეიყვანეთ პაროლი"
            />
            {errors.password && (
              <p className="error-msg">{errors.password.message}</p>
            )}
          </InputContainer>
          <Link to="/resetPassword" className="forget-password">
            პაროლის აღდგენა
          </Link>
          <input type="submit" className="submit-btn" disabled={!!loading} value="შესვლა" />
          <div className="signUp">
            <span>გინდა ვითანამშრომლოთ? </span><br />
            <Link to="#">გახდი პარტნიორი</Link>
          </div>
        </form>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
