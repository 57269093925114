import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models/AdminModels";
import { axiosInstance } from "Axios/axios";
import { logOut } from "./authSlice";
import { ActionTypes } from "@mui/base";
import { number } from "yup";

export interface PackagesState {
    loading: boolean;
    allCars: Models.CarsRes[];
    error: unknown;
}

const initialState: PackagesState = {
    loading: false,
    allCars: [],
    error: null,
};

export const getCarsAsync = createAsyncThunk("cars/getMakeList", async () => {
    return axiosInstance
        .get("/Dealership/GetMakeListAsync", {
            headers: {
                Authorization: "Bearer ".concat(
                    localStorage.getItem("bearerToken") as string
                ),
            },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
});

export const carsSlice = createSlice({
    name: "cars",
    initialState,
    reducers: {
        addCarBrand: (state, action) => {
            state.allCars.unshift(action.payload);
        },
        editCarBrand: (state, action) => {
            const carIndex = state.allCars.findIndex(
                (el) => el.id == action.payload.id
            );
            state.allCars[carIndex] = action.payload;
        },
        deleteCarBrand: (state, action) => {
            console.log(action);
            state.allCars = state.allCars.filter(
                (el) => el.id != action.payload
            );
        },
        // addCarModels: (state, action:PayloadAction<{makeId:number, modelNames:Models.ModelRes[]}>) => {
        //     const {makeId, modelNames} = action.payload;
        //     const indexOfMake = state.allCars.findIndex(el=>el.id==makeId);
        //     state.allCars[indexOfMake].models.unshift(...modelNames)

        // },
        // removeModel: (state, action:PayloadAction<{makeId:number, modelId:number}>) => {
        //     const {makeId, modelId} = action.payload;
        //     const indexOfMake = state.allCars.findIndex(el=>el.id==makeId);
        //     const updatedModels = state.allCars[indexOfMake].models.filter(el=>el.id !=modelId);
        //     state.allCars[indexOfMake].models = updatedModels;
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(getCarsAsync.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCarsAsync.fulfilled, (state, action) => {
            state.loading = false;
            state.allCars = action.payload;
            state.error = "";
        });
        builder.addCase(getCarsAsync.rejected, (state, action) => {
            state.loading = false;
            state.allCars = [];
            state.error = action.error;
        });
        builder.addCase(logOut, (state) => {
            state.allCars = [];
            state.error = "";
            state.loading = false;
        });
    },
});

export const { addCarBrand, editCarBrand, deleteCarBrand } = carsSlice.actions;
export default carsSlice.reducer;
