import React from "react";
import {
  Container,
  Logo,
  Nav,
  NavItem,
  SidebarBody,
  SidebarBtn,
} from "./styles";
import { Link } from "react-router-dom";

import logo from "Assets/images/Logo-New.svg";

// material icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import TextsmsIcon from "@mui/icons-material/Textsms";

import { logOut } from "Redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  return (
    <Container>
      <Logo>
        <Link to="/">
          <img src={logo} style={{width: 50}} alt="logo" />
        </Link>
      </Logo>
      <SidebarBody>
        <Nav>
          <NavItem end to="/admin">
            <DashboardIcon />
            <span>მთავარი</span>
          </NavItem>
          <NavItem to="/admin/partnersAllOffers">
            <LocalOfferIcon />
            <span>შეთავაზებები</span>
          </NavItem>
          <NavItem to="/admin/packages">
            <LocalOfferIcon />
            <span>პაკეტები</span>
          </NavItem>
          <NavItem to="/admin/expenses">
            <AccountBalanceWalletIcon />
            <span>ხარჯები</span>
          </NavItem>
          <NavItem to="/admin/translations">
            <GTranslateIcon />
            <span>თარგმნები</span>
          </NavItem>
          <NavItem to="/admin/cars">
            <DirectionsCarIcon />
            <span>გარაჟი</span>
          </NavItem>
          <NavItem to="/admin/waitings"><HourglassBottomIcon /><span>მოლოდინში მყოფი</span></NavItem>
          <NavItem to="/admin/advices"><TextsmsIcon /><span>რჩევები</span></NavItem>
          <NavItem to="/admin/faq"><TextsmsIcon /><span>FAQ</span></NavItem>
        </Nav>
        <Nav>
          <NavItem to="/settings">
            <SettingsIcon />
            <span>Settings</span>
          </NavItem>
          <NavItem to="/help-center">
            <HelpIcon />
            <span>Help Center</span>
          </NavItem>
          <SidebarBtn onClick={handleLogout}>
            <LogoutIcon />
            <span>Logout</span>
          </SidebarBtn>
        </Nav>
      </SidebarBody>
    </Container>
  );
};

export default Sidebar;
