import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface IInitialState {
    loading:boolean,
    FAQs:IFAQ[],
    error:string;
    searchValue:string;
}

interface IFAQ{   
    id:number,
    sortIndex: number,
    question: string,
    answer: string
}

const initialState: IInitialState = {
    loading:false,
    FAQs:[],
    error:'',
    searchValue:''
    
};

export const getFAQsAsyncThunk = createAsyncThunk(
    "app/FAQs",
    async () => {
        return axiosInstance
            .get("FAQ/GetAllAsync", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "bearerToken"
                    )}`,
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
        // return Promise.resolve(localTranslations);
    }
);

export const FAQSlice = createSlice({
    name: "translations",
    initialState,
    reducers: {
        addFAQ:(state, action:PayloadAction<IFAQ>)=>{
            state.FAQs.push(action.payload)

        },
        editFAQ:(state, action:PayloadAction<IFAQ>)=>{
            const foundIndex = state.FAQs.findIndex(
                (x: any) => x.id == action.payload.id
            );
            state.FAQs[foundIndex] = {...state.FAQs[foundIndex], ...action.payload}
        },
        deleteFAQ:(state, action:PayloadAction<number>)=>{
            state.FAQs = state.FAQs.filter(item=> item.id !== action.payload)
        },
        setSearchValue:(state, action:PayloadAction<string>)=>{
            state.searchValue = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFAQsAsyncThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFAQsAsyncThunk.fulfilled, (state, action) => {
            state.loading = false;
            state.FAQs = action.payload.sort(function(a:IFAQ,b:IFAQ){
                return a.sortIndex - b.sortIndex
            });
        });
        builder.addCase(getFAQsAsyncThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.toString();

        });
    },
});



export const { addFAQ, editFAQ, deleteFAQ, setSearchValue } = FAQSlice.actions;
export default FAQSlice.reducer;
