import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;

  box-sizing: border-box;
  padding: 24px 20px;

  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

export const Logo = styled.div`
  margin-bottom: 60px;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const NavItem = styled(NavLink)`
  width: 100%;
  min-width: 204px;
  box-sizing: border-box;
  padding: 14px 20px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 19px;
  color: #193a8d;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &.active {
    background-color: #eef4ff;
  }
  &:hover {
    background-color: #eef4ff;
  }
`;

export const SidebarBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 500px;
  gap: 30px;
  width: 100%;
`;

export const SidebarBtn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  min-width: 204px;
  box-sizing: border-box;
  padding: 14px 20px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 19px;
  color: #193a8d;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &:hover {
    background-color: #eef4ff;
  }
`;
