import { Box } from '@mui/system';
import { Header, Sidebar } from 'Components/AdminComponents'
import React, {FC} from 'react'
import styled from "@emotion/styled";

interface IProps {
  component:JSX.Element
}

export const AdminContainer:FC<IProps> = (props) => {
  return (
    <Box
    >
      <Sidebar />
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '287px',
          width: 'calc(100vw - 287px)',
          background: '#F8FCFF'
        }}>
        <Header />
        <Main>
        {props.component}
        </Main>
      </Box>

    </Box>
  )
}

export default AdminContainer;

const Main = styled.div`
    height:calc(100vh - 90px);
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:80px 40px 0 40px;
    overflow-y:auto;
    box-sizing:border-box;
    padding-bottom:40px;
    
`