import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

export const Container = styled(Paper)`
  box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
  border-radius: 24px;
  width: 100%;
  max-width: 924px;
  box-sizing: border-box;
  padding: 32px;
  
  .Container__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:28px;
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #326fe6;
  
  }
`;

export const SingleOffer = styled(Paper)`
  padding: 16px;
  .offer__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .offer__avatar {
      display: flex;
      align-items: center;
      gap: 16px;

      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #193a8d;
      }
    }
  }
  /* common */
  .badge{
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.16px;
        

        padding:7px 10px;
        border-radius: 16px;
    }
    .badge-active{
        background: #EDFFF6;
        color: #21E080;
    }

    .badge-discount{

        font-weight: 700;

        color: #F4724A;
        background: #FFF7F4;
    }
`;

export const OfferBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  h6 {
    color: #161c2d;
    margin-bottom:20px;
  }
  ul {
    padding-left: 15px;
    margin-bottom:20px;
  }
  ul li {
    list-style: disc;
    color: #869ab8;
  }

  button{
    border:none;
    outline:none;
    cursor:pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #326FE6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding:4px 10px;
  }
  button:disabled{
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
  }
`;
