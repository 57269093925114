import React from "react";

import { Button, Paper, TextField, Typography } from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useAddPackage } from "./hooks";
import { useNavigate } from "react-router";

export const InputSchema = yup.object({
  title: yup.string().required(),
  price: yup.number().required(),

});

interface IPackages {
  title: string;
  price: number;

}

const AddPackageContent: React.FC = () => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IPackages>({
    resolver: yupResolver(InputSchema),
  });

  const [loading, reqError, addPackageAsync] = useAddPackage();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<IPackages> = (data) => {
    addPackageAsync(data).then((res) => navigate('/admin/packages'))
  };
  return (
    <Paper
      sx={{
        padding: "20px",
        maxWidth:'300px',
        margin:'40px auto'
      }}
    >
      <Typography
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontWeight: "bolder",
        }}
      >
        დაამატე პაკეტი{" "}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <TextField
            id="title"
            placeholder="პაკეტის სახელი"
            label="პაკეტის სახელი"
            fullWidth
            margin="dense"
            {...register("title")}
            error={errors.title ? true : false}
          />
          <TextField
            type="number"
            id="price"
            placeholder="ფასი"
            label="ფასი"
            fullWidth
            margin="dense"
            {...register("price")}
            error={errors.price ? true : false}
          />

          

          <Button variant="contained" type="submit" disabled={loading}>
            დაამატე
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default AddPackageContent;
