import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Paper } from "@mui/material";
import { FC, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CostumTextField } from "../Shared/CostumInputs";
import { useAddCarBrand } from "./hooks";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelIcon from "@mui/icons-material/Cancel";
import FileUpload from "Components/Generals/FileUpload/FileUpload";

type IInputs = {
  title: string;
  image: File | string | undefined;
};
const InputSchema = yup.object({
  title: yup.string().required(),
  image: yup.string().required(),
});

const AddCarBrand: FC = () => {
  const navigate = useNavigate();
  const [offerImage, setOfferImage] = useState<File>();
  const { control, handleSubmit, setValue, clearErrors, watch } =
    useForm<IInputs>({
      resolver: yupResolver(InputSchema),
      defaultValues: {
        title: "",
      },
    });

  const [loading, error, addCarBrandAsync] = useAddCarBrand();

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    addCarBrandAsync({ title: data.title, image: offerImage }).then(() =>
      navigate("/admin/cars")
    );
  };

  return (
    <>
      <Paper
        sx={{
          padding: "20px",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px",
            }}
          >
            მწარმოებლის დამატება
          </Typography>
          <Stack spacing={3}>
            <CostumTextField label="title" name="title" control={control} />
            <FileUpload
              name={"image"}
              control={control}
              accept="image"
              setFormValue={setValue}
              handleFile={setOfferImage}
              clearErrors={clearErrors}
            />

            <Button variant="contained" type="submit" disabled={loading}>
              add car brand
            </Button>
          </Stack>
        </form>
      </Paper>
    </>
  );
};

export default AddCarBrand;
