import React, { useEffect, useState } from "react";

import {
  Button,
  Paper,
  TextField,
  Typography,
  Stack,
} from "@mui/material";

import { useGetUserByPhoneNumber } from "../hooks";
import { LoadingButton } from "@mui/lab";

const AssignUsers: React.FC<{ setUserIds: (ids: string[]) => void }> = ({
  setUserIds,
}) => {
  const [loading, error, users, fetchUser, deleteUser] =
    useGetUserByPhoneNumber();

  const [searchValue, setSearchValue] = useState<string>("");
  const [inputError, setInputError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setInputError("");
  };
  console.log(users);
  const handleSearch = () => {
    if (users.find((el) => el.phoneNumber === searchValue) !== undefined) {
      setInputError("მომხმარებელი უკვე დამატებულია");
    } else {
      fetchUser(searchValue).then(() => setSearchValue(""));
    }
    console.log("users", users);
  };

  useEffect(() => {
    const userIds = users.map((el) => el.id);
    setUserIds(userIds);
  }, [users]);

  return (
    <Paper
      sx={{
        padding: "20px",
      }}
      component={Stack}
      spacing={2}
    >
      <Typography textAlign={"center"}>აირჩიეთ მომხმარებლები</Typography>
      <TextField
        fullWidth
        id="standard-bare"
        variant="outlined"
        onChange={handleChange}
        value={searchValue}
        placeholder={"enter phone number"}
        helperText={inputError}
        error={!!inputError}
      />
      <LoadingButton
        onClick={handleSearch}
        loading={!!loading}
        variant="outlined"
        disabled={!!loading}
      >
        add user
      </LoadingButton>
      <Stack>
        {error && (
          <Typography
            sx={{ color: "red", fontSize: "12px", margin: "-10px 0 10px 0" }}
          >
            {error}
          </Typography>
        )}
        {users.length !== 0 ? (
          <Stack spacing={2}>
            {users?.map((user) => (
              <Paper
                key={user.id}
                sx={{
                  boxSizing: "border-box",
                  padding: "10px",
                  backgroundColor: "#aababf38",
                  position: "relative",
                }}
                component={Stack}
                spacing={1}
              >
                <Typography sx={{ "& span": { fontWeight: "bold" } }}>
                  <span>მობილურის ნომერი:</span> {user.phoneNumber}
                </Typography>
                <Typography sx={{ "& span": { fontWeight: "bold" } }}>
                  {" "}
                  <span>სახელი, გვარი:</span> {user.firstName} {user.lastName}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  sx={{ cursor: "pointer", width: "fit-content" }}
                  onClick={() => deleteUser(user.id)}
                >
                  წაშლა
                </Button>
              </Paper>
            ))}
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "12px", margin: "5px 0" }}>
            no uers are added
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default AssignUsers;
