import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface PackagesState {
  loading: boolean;
  allCategories: Models.GetCategoriesResponse[];
  error: unknown;
}
const initialState: PackagesState = {
  loading: false,
  allCategories: [],
  error: null,
};

export const getCategories = createAsyncThunk(
  "expense/expenseCategoriesList",
  async () => {
    return axiosInstance
      .get("/Expense/GetCategoriesAsync", {
        headers: {
          Authorization: "Bearer ".concat(
            localStorage.getItem("bearerToken") as string
          ),
        },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
);

export const ExpenseCategoriesSlice = createSlice({
  name: "expenseCategories",
  initialState,
  reducers: {
    deleteExpenseCategories: (state, action) => {
      const updatedExpenseCategories = state.allCategories.filter(
        (el) => el.id !== action.payload
      );

      state.allCategories = updatedExpenseCategories;
    },
    addExpenseCategorie: (state, action) => {
      state.allCategories.push(action.payload);
    },
    editExpenseCategorie: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.allCategories = action.payload;
      state.error = "";
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.loading = false;
      state.allCategories = [];
      state.error = action.error!.message!;
    });
  },
});

export const {
  deleteExpenseCategories,
  addExpenseCategorie,
  editExpenseCategorie,
} = ExpenseCategoriesSlice.actions;
export default ExpenseCategoriesSlice.reducer;
