import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, Button, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "Redux/store";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { deleteFAQ, editFAQ, getFAQsAsyncThunk } from "Redux/slices/FAQSlice";
import SortableList from "./SortableList";
type FAQProps = {};

const FAQList: React.FC<FAQProps> = () => {
  const { FAQs, loading, error, searchValue } = useAppSelector(
    (state) => state.faq
  );

  const [items, setItems] = useState<null | ISingleFaq[]>(FAQs)
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getFAQsAsyncThunk());
  }, []);

  useEffect(()=>{
    setItems(FAQs)
  },[FAQs])

  useEffect(() => {
   // console.log(items)
  }, [items])

  const setItemsHandle = (e:ISingleFaq[]) => {
    const arrayOfindexes = e.map(item=>item.sortIndex)
    setItems(e)
    console.log(arrayOfindexes)
  }

  const axiosInstancec = useAxiosPrivateInstance();

  interface IUpdateSortIndexAsync{
    id:number;
    sortIndex:number;
  }

  const [savingChanges, setSavingChanges] = useState(false);

  const handleSaveSorted = () => {

    const updateSortIndexAsync = async (data:IUpdateSortIndexAsync[]) => {
      setSavingChanges(true)
      await axiosInstancec.post('FAQ/UpdateSortIndexesAsync', data)
      .then(res=>{
        toast.success('შენახულია')
      })
      .catch(er=>toast.error(er))
      .finally(()=>{
        setSavingChanges(false)
      })

    }

    let sortIndexes = items?.map(el=>el.sortIndex).sort();
    const updatedItems = items?.map((item, index) => ({...item, sortIndex:sortIndexes ? sortIndexes[index] : 0}))
    const dataToSend = updatedItems?.map(({sortIndex, id})=>({sortIndex, id}))
    if(dataToSend != undefined){
      updateSortIndexAsync(dataToSend)
    }
    console.log(updatedItems)

  }

  const [sequenceIsChanged, setSequenceIsChanged] = useState(false);

  useEffect(()=>{
    
    if(JSON.stringify(FAQs) === JSON.stringify(items)){
      setSequenceIsChanged(false)
    } else {
      setSequenceIsChanged(true)
    }
  },[items])

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  

  return (
    <Paper component={Stack} spacing={4} sx={{ padding: "20px" }}>
      {/* {FAQs?.filter((item) => JSON.stringify(item).includes(searchValue)).map(
        (el) => (
          <SingleFAQ key={el.id} {...el} />
        )
      )} */}
      <Button sx={{width:'fit-content', marginLeft:'auto'}} variant="contained" color="success" onClick={handleSaveSorted} disabled={Boolean(!sequenceIsChanged || savingChanges)}>შეინახეთ მიმდევრობა</Button>
      {items && <SortableList
        items={items}
        onChange={setItemsHandle}
        renderItem={(item) => (
          <SortableList.Item id={item.id}>
            <SingleFAQ {...item}/>
          </SortableList.Item>
        )}
      />}

    </Paper>
  );
};

export default FAQList;

interface ISingleFaq {
  id: number;
  sortIndex: number;
  question: string;
  answer: string;
}

export const SingleFAQ: FC<ISingleFaq> = (props) => {
  const { id, sortIndex, question, answer } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [saving, setSving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [questionInput, setQuestionInput] = useState(question);
  const [answerInput, setAnswerInput] = useState(answer);

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useDispatch();

  const cancelEditHandler = () => {
    setIsEditing(false);
    setQuestionInput(question);
    setAnswerInput(answer);
  };

  const saveFAQHandler = async () => {
    setSving(true);
    await axiosPrivateInstance
      .post("FAQ/EditByIdAsync", { id, sortIndex, question:questionInput, answer:answerInput })
      .then((res) => {
        dispatch(editFAQ({ id, sortIndex, question:questionInput, answer:answerInput }));
        toast.success("edited");
        setIsEditing(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setSving(false));
  };

  const deleteFAQAsync = async () => {
    setDeleting(true);
    await axiosPrivateInstance
      .delete(`FAQ/DeleteByIdAsync/${id}`)
      .then((res) => {
        dispatch(deleteFAQ(id));
        toast.success("წარმატებით წაიშალა");
        setIsEditing(false);
      })
      .catch((err) => toast.error(err.toString()))
      .finally(() => setDeleting(false));
  };

  return (
    <Paper
      component={Stack}
      direction="row"
      spacing={4}
      sx={{ padding: "10px" }}
    >
      {!isEditing ? (
        <Box
          sx={{ width: "100%" }}
          component={Stack}
          direction="row"
          spacing={4}
        >
          <Paper sx={{ width: "50%", padding: "10px", position: "relative" }}>
            <Typography>{question}</Typography>
          </Paper>
          <Paper sx={{ width: "50%", padding: "10px", position: "relative" }}>
            <Typography>{answer}</Typography>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{ width: "100%" }}
          component={Stack}
          direction="row"
          spacing={4}
        >
          <TextField
            value={questionInput}
            multiline={true}
            fullWidth
            onChange={(e) => setQuestionInput(e.target.value)}
            rows={4}
            sx={{ backgroundColor: "#87cefa44" }}
            placeholder="კითხვა"
          />
          <TextField
            value={answerInput}
            multiline={true}
            fullWidth
            onChange={(e) => setAnswerInput(e.target.value)}
            rows={4}
            sx={{ backgroundColor: "#87cefa44" }}
            placeholder="პასუხი"
          />
        </Box>
      )}
      <Stack spacing={2}>
        {isEditing ? (
          <IconButton
            aria-label="cancel-edit"
            size="small"
            onClick={cancelEditHandler}
          >
            <DoDisturbOnIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => setIsEditing((state) => !state)}
          >
            <EditIcon fontSize="inherit" color="secondary" />
          </IconButton>
        )}
        <IconButton aria-label="delete" size="small" disabled={deleting} onClick={deleteFAQAsync}>
          {deleting ? (
            <CircularProgress size={20} />
          ) : (
            <DeleteIcon fontSize="inherit"  color="error" />
          )}
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={saveFAQHandler}
          disabled={
            saving || (answer === answerInput && question === questionInput)
          }
        >
          {saving ? (
            <CircularProgress size={20} />
          ) : (
            <SaveIcon
              fontSize="inherit"
             
              color="success"
              sx={{
                opacity:
                  saving ||
                  (answer === answerInput && question === questionInput)
                    ? 0.5
                    : 1,
              }}
            />
          )}
        </IconButton>
        <SortableList.DragHandle />
      </Stack>
    </Paper>
  );
};
