import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "./slices/authSlice";
import packagesReducer from "./slices/packagesSlice";
import partnersAllOffersReducer from "./slices/partnersAllOffersSlice";
import carsReducer from "./slices/carsSlice";
import singleCarReducer from "./slices/singleCarSlice";
import translationsReducer from "./slices/translationsSlice";
import expenseCategoriesReducer from "./slices/expenseSlice";
import faqReducer from "./slices/FAQSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    packages: packagesReducer,
    partnersAllOffers: partnersAllOffersReducer,
    cars: carsReducer,
    singleCar: singleCarReducer,
    translations: translationsReducer,
    expenseCategories: expenseCategoriesReducer,
    faq: faqReducer,
  },
  devTools: process.env.REACT_APP_ENV !== "production",
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
