import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "Redux/store";

const MainRouteSwitch: FC = () => {

    const authState = useSelector((state: RootState) => state.auth);

    if (authState.userInfo.role != '' && authState.isLoggedIn == true) {
        if (authState.userInfo.role.toLowerCase() === 'admin') {
            //console.log('userState.role.toLowerCase()', authState.userInfo.role.toLowerCase());
            return <Navigate to="/admin" />
        };
        if (authState.userInfo.role.toLowerCase() !== 'admin') {
            //console.log('userState.role.toLowerCase()', authState.userInfo.role.toLowerCase());
            return <Navigate to="/forbidden" />
        };
    }
    return <Navigate to="/login" />
}

export default MainRouteSwitch;