import styled from "@emotion/styled";

export const Container = styled.div`

display:flex;
align-items:center;
justify-content:flex-end;
gap:30px;
box-sizing:border-box;
padding:24px 30px;
height:90px;
svg{
    cursor:pointer;
}
`

export const Name = styled.button`
outline:none;
border:none;
display:flex;
align-items:center;
justify-content:center;
gap:25px;
padding:10px;
padding-right:50px;
background: #EEF4FF;
border-radius: 100px;
position:relative;
color:#326FE6;
cursor:pointer;
span{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}

svg{
    position:absolute;
    right:7px;
    top:50%;
    transform:translateY(-50%)
}
`