import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react";
import { toast } from "react-toastify";
import { addCarBrand, editCarBrand } from "Redux/slices/carsSlice";
import { useAppDispatch } from "Redux/store";
import * as Models from "Models/AdminModels";
import { useDispatch } from "react-redux";
import { addModels, editModel, removeModel } from "Redux/slices/singleCarSlice";

interface ICarBrandInputs {
  id?: number;
  title: string;
  image: File | undefined;
}

export const useAddCarBrand = (): [
  boolean,
  string,
  (data: ICarBrandInputs) => Promise<void>
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useAppDispatch();

  const addCarBrandAsync = async (data: ICarBrandInputs) => {
    setLoading(true);

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value);
    }
    await axiosPrivateInstance
      .post("Dealership/AddMakeAsync", formData)
      .then((res) => {
        dispatch(addCarBrand(res.data));
        toast.success("car make added");
      })
      .catch((er) => {
        toast.error("oops something went wrong");
        setError(er.message);
        console.log(er);
      })
      .finally(() => {
        setLoading(false);
        setError("");
      });
  };

  return [loading, error, addCarBrandAsync];
};

export const useEditCarBrand = (): [
  boolean,
  string,
  (data: ICarBrandInputs) => Promise<void>
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useAppDispatch();

  const editCarBrandAsync = async (data: ICarBrandInputs) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value);
    }

    setLoading(true);
    axiosPrivateInstance
      .post("Dealership/EditMakeByIdAsync", formData)
      .then((res) => {
        dispatch(editCarBrand(res.data));
        toast.success("car make edited");
      })
      .catch((er) => {
        toast.error("oops something went wrong");
        setError(er.message);
        console.log(er);
      })
      .finally(() => {
        setLoading(false);
        setError("");
      });
  };

  return [loading, error, editCarBrandAsync];
};

export const useAddModels = (): [boolean, string, typeof addModelAsync] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useDispatch<any>();

  const addModelAsync = async (makeId: number, modelNames: string[]) => {
    setLoading(true);
    await axiosPrivateInstance
      .post("Dealership/AddModelsAsync", { makeId, modelNames })
      .then((res) => {
        dispatch(addModels(res.data));
        toast.success("model added");
      })
      .catch((er) => {
        toast.error("oops something went wrong");
        setError(er.message);
      })
      .finally(() => {
        setLoading(false);
        setError("");
      });
  };

  return [loading, error, addModelAsync];
};

export const useRemoveOrEditModel = (): [
  boolean,
  boolean,
  typeof removeModelAsync,
  typeof editCarModedlAsync
] => {
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useAppDispatch();

  const removeModelAsync = async (modelId: number) => {
    setLoading(true);
    await axiosPrivateInstance
      .get(`Dealership/RemoveModelByIdAsync/${modelId}`)
      .then((res) => {
        dispatch(removeModel(modelId));
        toast.success("model deleted");
      })
      .catch((er) => {
        toast.error(er.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCarModedlAsync = async (data: {
    title: string;
    makeId: number;
    modelId: number;
  }) => {
    setLoadingEdit(true);
    await axiosPrivateInstance
      .post("Dealership/EditModelByIdAsync", data)
      .then((res) => {
        dispatch(editModel(res.data));
        toast.success("car make added");
      })
      .catch((er) => {
        toast.error("oops something went wrong");
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  };

  return [loading, loadingEdit, removeModelAsync, editCarModedlAsync];
};

export const useGetSingleMake = (): [
  boolean,
  Models.CarsRes | null,
  typeof getSingleMakeAsync
] => {
  const [loading, setLoading] = useState(false);
  const [make, setMake] = useState<Models.CarsRes | null>(null);

  const axiosPrivateInstance = useAxiosPrivateInstance();

  const getSingleMakeAsync = async (makeId: number) => {
    setLoading(true);
    await axiosPrivateInstance
      .get(`Dealership/GetMakeByIdAsync/${makeId}`)
      .then((res) => {
        setMake(res.data);
      })
      .catch((er) => {
        toast.error(er.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [loading, make, getSingleMakeAsync];
};
