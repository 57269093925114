import { FC, useEffect, useState } from "react";
import * as Models from "Models";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,

} from "./styles";
import { useNavigate } from "react-router-dom";

import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, useTheme } from "@mui/material";
import { axiosInstance } from "Axios/axios";
import { toast } from "react-toastify";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Stack } from "@mui/system";
import * as yup from 'yup';


interface IResetPsswordInputs {
  phoneNumber: string;
  newPassword: string;
  otp: string;
}
const phoneRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const ResetPasswordSchema = yup
  .object({
    phoneNumber: yup.string().matches(phoneRegEx, 'მიუთითეთ სწორი ფორმატით').required('მიუთითეთ ტელეფონის ნომერი'),
    otp: yup.string().required('მიუთითეთ SMS კოდი'),
    newPassword: yup.string().required('მიუთითეთ პაროლი'),
    //   confirmPsw: yup.string().required().oneOf([yup.ref('password')], 'პაროლი არ ემთხვევა'),

  })
  .required();

export const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState([])
  const theme = useTheme()
  const {
    control,
    handleSubmit,
    watch
  } = useForm<IResetPsswordInputs>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const onSubmit = (data: IResetPsswordInputs) => {

    const resetAsync = async () => {
      setLoading(true)
      try {

        const response = await axiosInstance.post('Account/ResetPasswordAsync', data);
        if (response.data.succeeded) {
          toast.success('ოპერაცია წარმატებით განხორციელდა');
          navigate('/login')
        } else {
          throw Error('დაფიქსირდა შეცდომა')
        }
      } catch (err) {

        toast.error('დაფიქსირდა შეცდომა')
        console.log(err)

      } finally {
        setLoading(false)
      }

    }
    resetAsync()

  };



  return (
    <>
      <Container>
        <h2>პაროლის აღდგენა</h2>
        {serverErrors.length !== 0 && (
          <ul className="server__errors">
            {serverErrors?.map((er, ind) => (
              <li key={ind}>{er}</li>
            ))}
          </ul>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <CostumTextField name={"phoneNumber"} label={"ნომერი"} control={control} />
          <CostumPasswordField name={"newPassword"} label={"ახალი პაროლი"} control={control} />
          <Stack><CostumTextField name={"otp"} label={"sms კოდი"} control={control} /><SendSms phoneNumber={watch('phoneNumber')} /></Stack>
          <Button
            disableElevation={true}
            onClick={handleSubmit(onSubmit)} variant="contained" sx={{ borderRadius: theme.button.borderRadius, padding: '16px' }}
            disabled={!!loading}
          >პაროლის აღდგენა</Button>

        </form>
      </Container>
    </>
  );
};



interface ISendSms {
  initialMinute?: number;
  initialSeconds?: number;
  phoneNumber: string;
}

const SendSms: FC<ISendSms> = (props) => {
  const { initialMinute = 2, initialSeconds = 0, phoneNumber } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [buttonText, setButtonText] = useState('კოდის გაგზავნა')

  const restartTimer = () => {
    setMinutes(initialMinute)
    setSeconds(initialSeconds)
    setButtonText('ახლიდან გაგზავნა')
  }

  const [loading, setLoading] = useState(false)

  const handleSendSms = async () => {
    setLoading(true)
    await axiosInstance
      .get(`SmsService/SendOtp/${phoneNumber}`)
      .then((res) => {
        toast.success("სმს გაიგზავნა თქვენს ნომერზე");
        restartTimer()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);

      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <Box sx={{ margin: '5px 0', cursor: (minutes !== 0 || seconds !== 0) ? 'not-allowed' : 'pointer', width: 'fit-content' }}>
        <Button
          variant="contained"
          sx={{ position: "relative", overflow: "hidden" }}
          onClick={handleSendSms}
          disabled={(minutes !== 0 || seconds !== 0 || loading || Boolean(phoneNumber?.length < 5))}
        >
          {loading ? <>იგზავნება...</> : buttonText}

          <Box
            sx={{
              transition: 0.5,
              opacity: 0.5,
              width: `${((minutes * 60 + seconds) / (initialMinute * 60 + initialSeconds)) * 100

                }%`,
              backgroundColor: "rgba(128,128,128,0.75)",
              position: "absolute",
              height: "100%",
              left: 0,
              top: 0,
              zIndex: 2312313,

            }}
          ></Box>
        </Button>
      </Box>
    </>
  );
}


// costum inputs
interface ICostumTextField {
  name: string;
  label: string;
  control: any;
}

const CostumPasswordField: React.FC<ICostumTextField> = ({
  name,
  label,
  control,

}) => {

  const [showPassword, setShowPassword] = useState(false)
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl >
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            defaultValue={""}
            label={label}
            {...field}
            error={!!error}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(state => !state)}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }

          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

const CostumTextField: React.FC<ICostumTextField> = ({
  name,
  label,
  control
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
            label={label}
            defaultValue={""}
            {...field}
            variant="outlined"
            error={!!error}
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};