import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Models from "Models/AdminModels";
import { axiosInstance } from "Axios/axios";

interface PartnerWithOffer extends Models.Partner {
    offers: Models.Offer[];
}

interface PartnersAllOffersState {
    loading: boolean;
    partnersAllOffers: PartnerWithOffer[];
    error: string;
}

const initialState: PartnersAllOffersState = {
    loading: false,
    partnersAllOffers: [],
    error: "",
};

export const getPartnersOffers = createAsyncThunk(
    "Admin/GetAllPartnersWithOffersAsync",
    async () => {
        return axiosInstance
            .get(`Admin/GetAllPartnersWithOffersAsync`, {
                headers: {
                    Authorization: "Bearer ".concat(
                        localStorage.getItem("bearerToken") as string
                    ),
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
);

export const partnersAllOffersSlice = createSlice({
    name: "partnersAllOffers",
    initialState,
    reducers: {
        clearPartnersOffers: (state) => {
            state = initialState;
        },
        deleteOfferForPartner: (state, action) => {
            const indexOfPartner = state.partnersAllOffers.findIndex(
                (el) => el.id == action.payload.partnerId
            );
            const partner = state.partnersAllOffers[indexOfPartner];
            const updatedPartnerOffers = partner.offers.filter(
                (el) => el.id !== action.payload.offerId
            );

            state.partnersAllOffers[indexOfPartner].offers =
                updatedPartnerOffers;
        },
        addOfferForPartner: (state, action) => {
            console.log("payload", action.payload);
            const indexOfPartner = state.partnersAllOffers.findIndex(
                (el) => el.id == action.payload.partnerId
            );
            state.partnersAllOffers[indexOfPartner].offers.unshift(
                action.payload
            );
        },
        editOfferForPartner: (state, action) => {
            console.log(action.payload);
            const indexOfPartner = state.partnersAllOffers.findIndex(
                (el) => el.id == action.payload.partnerId
            );
            const partner = state.partnersAllOffers[indexOfPartner];
            const indexOfOffer = partner.offers.findIndex(
                (el) => el.id === action.payload.offer.id
            );

            state.partnersAllOffers[indexOfPartner].offers[indexOfOffer] =
                action.payload.offer;

            // const foundIndex = state.partnersAllOffers.findIndex(
            //     (x: any) => x.uniqueId == action.payload.uniqueId
            // );
            // state.partnersAllOffers[foundIndex] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPartnersOffers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPartnersOffers.fulfilled, (state, action) => {
            state.loading = false;
            state.partnersAllOffers = action.payload;
            state.error = "";
        });
        builder.addCase(getPartnersOffers.rejected, (state, action) => {
            state.loading = false;
            state.partnersAllOffers = [];
            state.error = action.error!.message!;
        });
    },
});

export const {
    clearPartnersOffers,
    deleteOfferForPartner,
    addOfferForPartner,
    editOfferForPartner,
} = partnersAllOffersSlice.actions;
export default partnersAllOffersSlice.reducer;
