import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Paper, Typography } from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "Redux/store";
import Stack from "@mui/material/Stack";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import {
  CostumCheckbox,
  CostumTextField,
} from "Components/AdminComponents/Shared/CostumInputs";
import { useEditExpenseCategorie } from "../hooks";
import FileUpload from "Components/Generals/FileUpload/FileUpload";

const InputSchema = yup.object({
  title: yup.string().required(),
  color: yup.string().required(),
  image: yup.string(),
  pattern: yup.string().required(),
});

interface IInputs {
  title: string;
  pattern: string;
  image: File | string | undefined;
  imageUrl: string;
  color: string;
}

const EditExpenseCategories: React.FC = () => {
  const navigate = useNavigate();
  const [offerImage, setOfferImage] = useState<File>();
  const categories = useSelector(
    (state: RootState) => state.expenseCategories.allCategories
  );

  const handleClose = () => {
    navigate("/admin/expenses");
  };
  const { id } = useParams();
  const newCategory = categories.filter((el) => {
    return el.id == Number(id);
  });

 
  const { control, handleSubmit, reset, watch, setValue } = useForm<IInputs>({
    resolver: yupResolver(InputSchema),
    defaultValues: {
      title: newCategory[0].title,
      pattern: newCategory[0].pattern,
      color: newCategory[0].color,
    },
  });

  const [loading, editPartnerOfferAsync] = useEditExpenseCategorie();

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    editPartnerOfferAsync({
      ...data,
      id: Number(id),
      image: offerImage,
    });
  };
  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <Paper
        sx={{
          padding: "20px",
          width: "100%",
          maxWidth: 500,
          margin: "20px auto",
        }}
      >
        <Typography
          sx={{
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          edit Expense Categories
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <CostumTextField
              name={"title"}
              label={"title"}
              control={control}
              InputLabelProps={{
                shrink: watch("title") ? true : false,
              }}
            />
            <CostumTextField
              name={"pattern"}
              label={"pattern"}
              control={control}
              InputLabelProps={{
                shrink: watch("pattern") ? true : false,
              }}
            />
            <FileUpload
              name={"image"}
              control={control}
              accept="image"
              setFormValue={setValue}
              handleFile={setOfferImage}
            />
            <CostumTextField
              label="color"
              name="color"
              control={control}
              type="color"
              InputLabelProps={{
                shrink: true,
              }}
            ></CostumTextField>

            <Button variant="contained" type="submit" disabled={loading}>
              save
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
};

export default EditExpenseCategories;
