import { Box, Checkbox, FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import useAxiosPrivateInstance from 'Hooks/useAxiosPrivateInstance';
import React, { useEffect, useState } from 'react';


import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

type AdvicesProps = {


};

interface IAdvice {
    adviceText:string;
    dateOfCreate:string;
    id:string;
}


const Advices:React.FC<AdvicesProps> = () => {
    
const axiosInstance = useAxiosPrivateInstance();    
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const [advicesData, setAdvicesData] = useState<IAdvice[]>()


useEffect(()=>{
    setLoading(true)
    axiosInstance.get('Admin/GetAllFeedbacksAsync')
    .then(res=>{ setAdvicesData(res.data)})
    .catch((err)=>{
        setError(err)
    })
    .finally(()=>{
        setLoading(false)
    })

},[])


    


    if(loading){return <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif" alt=""/>}
    if(error){return <p>something went wrong:{error}</p>}
    return <Box>
        <Box>
            <Typography sx={{fontSize:'25px', margin:'20px 0', color:'#326FE6'}}>მომხმარებლის რჩევები</Typography>
            
<Typography sx={{margin:'20px 0'}}>სულ: {advicesData?.length}</Typography>
        </Box>
        <Stack spacing={1}>
        {advicesData?.map(el=><Card key={el.id} {...el}/>)}

        </Stack>
        </Box>
}
export default Advices;


const Card:React.FC<IAdvice> = (props)=>{

    return <Paper  component={Stack} >
       <Stack direction="column" spacing={3} p={2}>
        <Typography>{props.adviceText || 'empty'}</Typography>
        <Typography textAlign={'right'} fontSize={12}>{dayjs(props.dateOfCreate).format("dddd, MMMM D YYYY")}</Typography>
        </Stack>
    </Paper>
}


