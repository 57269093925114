import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import logo from "Assets/images/Logo-New.svg";

import { Container } from "./styles";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Header: FC = () => {
    return (
        <div style={{
            textAlign: "center",
            padding: "50px 0px"
        }}>
            <Link to="/"><img src={logo} style={{width: 50  }}alt="AutoCircle" /></Link>
        </div>
    )
}

export default Header;