import { CarsList } from 'Components/AdminComponents/GarageComponents'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from './styles'


const Garage:React.FC = () => {
  return (<Container>
    <div className="offers__heading">
        <h4>admin Garage</h4>
        <CarsList/>
      </div>
      <Outlet/>
  </Container>
  )
}

export default Garage