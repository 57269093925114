import { Box, Checkbox, FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import useAxiosPrivateInstance from 'Hooks/useAxiosPrivateInstance';
import React, { useEffect, useState } from 'react';


import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

type WaitingsProps = {
    
};


interface INumber {
    dateOfCreate:string;
    id:number;
    isPartner:boolean;
    name:string;
    number:string;

}
const Waitings:React.FC<WaitingsProps> = () => {
    
const axiosInstance = useAxiosPrivateInstance();    
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const [numbers, setNumbers] = useState<INumber[] | null>(null)
const [displayData, setDisplayData] = useState<INumber[] | null>()

const [showOnlyPartners, setShowOnlyPartners] = useState(false);

useEffect(()=>{
    setLoading(true)
    axiosInstance.get('Admin/GetAllSavedPhoneNumbersAsync')
    .then(res=>{setNumbers(res.data); setDisplayData(res.data)})
    .catch((err)=>{
        setError(err)
    })
    .finally(()=>{
        setLoading(false)
    })

},[])

useEffect(()=>{
    if(showOnlyPartners){
        const res = numbers?.filter(el=>el.isPartner);
        setDisplayData(res);
        console.log(res)
    } else {
        setDisplayData(numbers)
        console.log(numbers)
    }
    
},[showOnlyPartners])

    if(loading){return <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif" alt=""/>}
    if(error){return <p>something went wrong:{error}</p>}
    return <Box>
        <Box>
            <Typography sx={{fontSize:'25px', margin:'20px 0', color:'#326FE6'}}>მომლოდინეთა ტელეფონის ნომრები</Typography>
            <FormControlLabel
  label="მაჩვენე მხოლოდ პარტნიერები"
  control={
    <Checkbox
      checked={showOnlyPartners}
      onChange={()=>setShowOnlyPartners(state=>!state)}
    />
  }
/>
<Typography sx={{margin:'20px 0'}}>სულ: {displayData?.length}/{numbers?.length}</Typography>
        </Box>
        <Stack spacing={1}>
{displayData?.map(el=><Card key={el.id} {...el}/>)}

        </Stack>
        </Box>
}
export default Waitings;


const Card:React.FC<INumber> = (props)=>{

    return <Paper  component={Stack} sx={{backgroundColor:`${props.isPartner ? '#67a2b78d' :'white'}`}}>
      {props.name && <Box sx={{ padding:'10px 16px', borderBottom:'1px solid gray'}}>სახელი:{props.name}</Box>}
       <Stack direction="row" justifyContent={'space-between'} spacing={3} p={2}>
        <Typography>{props.number}</Typography>
        <Typography>{dayjs(props.dateOfCreate).format("dddd, MMMM D YYYY")}</Typography>
        </Stack>
    </Paper>
}


