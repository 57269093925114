import { Paper, Typography } from '@mui/material';
import AddFAQ from 'Components/AdminComponents/FAQComponents/AddFAQ';
import FAQList from 'Components/AdminComponents/FAQComponents/FAQList';
import React from 'react';

type FAQProps = {
    
};

const FAQ:React.FC<FAQProps> = () => {
    
    return <Paper sx={{width:'100%'}}>
    <Typography sx={{color:'rgb(35, 77, 161)', padding:'20px 10px 0 20px', fontSize:'20px', fontWeight:'bolder'}}>FAQ</Typography>
    <AddFAQ/>
    <FAQList/>
    </Paper>
}
export default FAQ;