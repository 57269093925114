import dayjs from "dayjs";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addOfferToPackage, addPackage, editOfferOfPackage, editPackage } from "Redux/slices/packagesSlice";
import { useAppDispatch } from "Redux/store";
import * as Models from "Models";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IPackage {
    title: string;
    price: number;
    validFrom?: string | null;
    validTill?: string | null;
    offers?: { uniqueId: string; title: string };
}

export const useAddPackage = (): [boolean, string, typeof addPackageAsync] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reqError, setReqError] = useState<string>("");

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const addPackageAsync = async (data: IPackage) => {
        setLoading(true);
        await axiosPrivateInstance
            .post("Package/AddPackageAsync", {
                title: data.title,
                price: data.price,
                validFrom: dayjs(data.validFrom).toISOString(),
                validTill: dayjs(data.validTill).toISOString(),
            })
            .then((res) => {
                dispatch(addPackage(res.data));
                toast.success("package added");
                setSearchParams(`packageId=${res.data.uniqueId}`);
            })
            .catch((er) => {
                console.log(er);
                toast.error("oops something went wrong");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [loading, reqError, addPackageAsync];
};

export const useFetchOffers = (): [
    Models.OfferModel[],
    typeof setOffers,
    boolean
] => {
    const axiosPrivateInstance = useAxiosPrivateInstance();

    const [offers, setOffers] = useState<Models.OfferModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        axiosPrivateInstance
            .get("Admin/GetAllOffersAsync")
            .then((res) => {
                const filteredData = res.data.filter((offer:any)=>offer.isApplicableToAssignToPackage===true)
                setOffers(filteredData);
            })
            .catch((er) => {
                toast.error(" as oops something went wrong");
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return [offers, setOffers, loading];
};

interface IAssignOffersData {
    packageId: string | null | undefined;
    offer:IOfferInput
}

interface IOfferInput {
    isUsageLimited?: boolean,
    useCount?: number,
    discountPercent?: number
  }
export const useAssignOffers = (): [
    boolean,
    string,
    typeof assignOfferAsync
] => {
    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [reqError, setReqError] = useState<string>("");



    const assignOfferAsync = async (data: IAssignOffersData) => {
        setLoading(true);
        await axiosPrivateInstance
            .post("Package/AssignOfferToPackageAsync", data)
            .then((res) => {
                dispatch(addOfferToPackage({offer:res.data.lastAddedOffer, packageId:res.data.uniqueId}))
                toast.success("offers are assigned");
                navigate('/admin/packages')
    
            })
            .catch((er) => {
                setReqError(er);
                toast.error(er.response.data);
   
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [loading, reqError, assignOfferAsync];
};

// edit offers

export const useEditPackage = (): [
    boolean,
    string,
    typeof editPackageAsync
] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reqError, setReqError] = useState<string>("");

    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();

    const editPackageAsync = async (
        data: IPackage,
        packageId: string | undefined
    ) => {
        setLoading(true);
        console.log(dayjs(data.validFrom).toISOString());
        axiosPrivateInstance
            .post("Package/EditPackageAsync", {
                title: data.title,
                price: data.price,
                validFrom: dayjs(data.validFrom).toISOString(),
                validTill: dayjs(data.validTill).toISOString(),
                packageId,
            })
            .then((res) => {
                dispatch(editPackage(res.data));
                toast.success("package added");
            })
            .catch((er) => {
                toast.error("oops something went wrong");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [loading, reqError, editPackageAsync];
};


// edit offer in package


interface IEditOptions{
  packageId: string,
  offerId: string,
  isUsageLimited: boolean,
  useCount: number,
  discountPercent: number,
}

export const useEditOptions = (): [
    boolean,
    string,
    typeof editOptionsAsync
] => {
    const axiosPrivateInstance = useAxiosPrivateInstance();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [reqError, setReqError] = useState<string>("");



    const editOptionsAsync = async (data: IEditOptions) => {
        setLoading(true);
        await axiosPrivateInstance
            .post("Offer/EditOptionsAsync", data)
            .then((res) => {
                dispatch(editOfferOfPackage({offerId:data.offerId, packageId:data.packageId, offer:data}))
                toast.success("offer is edited");
                navigate('/admin/packages')
    
            })
            .catch((er) => {
                toast.error(er);
   
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [loading, reqError, editOptionsAsync];
};