import Paper from "@mui/material/Paper";
import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addTranslation, setSearchValue } from "Redux/slices/translationsSlice";
import { addFAQ } from "Redux/slices/FAQSlice";
import { useAppSelector } from "Redux/store";

type AddTranslationProps = {};

const AddFAQ: React.FC<AddTranslationProps> = () => {
  const [openAdding, setOpenAdding] = useState(false);
  const [adding, setAdding] = useState(false);
  const [unswerInput, setAnswerInput] = useState("");
  const [questionInput, setQuestionInput] = useState("");

  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useDispatch();
  const faqs = useAppSelector((state) => state.faq.FAQs);

  const addFaqHandler = async () => {
    setAdding(true);
    let sortIndex = faqs.length !== 0 ? faqs[faqs.length - 1].sortIndex : -1;
    await axiosPrivateInstance
      .post("FAQ/AddAsync", {
        answer: unswerInput,
        question: questionInput,
        sortIndex: sortIndex + 1,
      })
      .then((res) => {
        toast.success("დაემატა");
        dispatch(addFAQ(res.data));
        setAnswerInput("");
        setQuestionInput("");
      })
      .catch((err) => toast.error(err))
      .finally(() => setAdding(false));
  };

  const cancelAddingHandler = () => {
    setOpenAdding(false);
    setAnswerInput("");
    setQuestionInput("");
  };

  return (
    <Paper sx={{ padding: "20px" }} component={Stack} spacing={2}>
      <Stack direction={"row"}>
        {/* <SearchInput /> */}
        <Button
          variant="contained"
          sx={{ width: "fit-content", marginLeft: "auto" }}
          onClick={() => setOpenAdding(true)}
        >
          + დამატება
        </Button>
      </Stack>
      {openAdding && (
        <Paper sx={{ padding: "10px" }} elevation={4}>
          <Box
            sx={{ width: "100%" }}
            component={Stack}
            direction="row"
            spacing={4}
          >
            <TextField
              placeholder="კითხვა"
              value={questionInput}
              multiline={true}
              fullWidth
              onChange={(e) => setQuestionInput(e.target.value)}
              rows={4}
              sx={{ backgroundColor: "#87cefa44" }}
              label="კითხვა"
            />
            <TextField
              placeholder="პასუხი"
              value={unswerInput}
              multiline={true}
              fullWidth
              onChange={(e) => setAnswerInput(e.target.value)}
              rows={4}
              sx={{ backgroundColor: "#87cefa44" }}
              label="პასუხი"
            />
          </Box>
          <Stack
            sx={{ width: "fit-content", margin: "20px 0", marginLeft: "auto" }}
            direction="row"
            spacing={2}
          >
            <Button
              variant="contained"
              color="success"
              onClick={addFaqHandler}
              disabled={adding}
            >
              შენახვა
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={cancelAddingHandler}
            >
              გაუქმება
            </Button>
          </Stack>
        </Paper>
      )}
    </Paper>
  );
};
export default AddFAQ;

const SearchInput: React.FC = () => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<number | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      dispatch(setSearchValue(event.target.value));
      console.log(event.target.value);
    }, 200);
  };

  return (
    <TextField
      label="მოძებნე კითხვა"
      onChange={handleChange}
      size="small"
      fullWidth
      sx={{ maxWidth: "300px" }}
    />
  );
};
