import { FC, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import * as Models from "Models/AdminModels";
import { Box, Stack, Paper } from "@mui/material";
import { useEditCarBrand } from "./hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CostumTextField } from "../Shared/CostumInputs";
import { TextField, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FileUpload from "Components/Generals/FileUpload/FileUpload";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type IInputs = {
  title: string;
  imageUrl: string;
};

const InputSchema = yup.object({
  title: yup.string().required(),
  image: yup.string(),
});

const EditMakeContent: FC<{
  carMake: Models.CarsRes | null;
  loadingMake: boolean;
}> = ({ carMake, loadingMake }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IInputs>({
    resolver: yupResolver(InputSchema),
    defaultValues: {
      title: carMake?.title,
    },
  });

  const [loading, error, editCarBrandAsync] = useEditCarBrand();
  const [imageUrl, setImage] = useState<string>("");

  const [offerImage, setOfferImage] = useState<File>();

  useEffect(() => {
    reset({ title: carMake?.title });
    if (typeof carMake?.imageUrl === "string") {
      setImage(carMake?.imageUrl);
    }
    console.log(carMake);
  }, [carMake]);

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    if (typeof id === "string") {
      editCarBrandAsync({
        title: data.title,
        image: offerImage,
        id: Number(id),
      }).then(() => navigate("/admin/cars"));
    }
  };

  if (loadingMake) {
    return <CircularProgress />;
  }
  return (
    <div>
      <Paper
        sx={{
          padding: "20px",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px",
            }}
          >
            მწარმოებლის დამატება
          </Typography>
          <Stack spacing={3}>
            <CostumTextField label="title" name="title" control={control} />
            <FileUpload
              name={"image"}
              control={control}
              accept="image"
              setFormValue={setValue}
              handleFile={setOfferImage}
              clearErrors={clearErrors}
            />

            <Button variant="contained" type="submit" disabled={loading}>
              add car brand
            </Button>
          </Stack>
        </form>
      </Paper>
    </div>
  );
};

export default EditMakeContent;
