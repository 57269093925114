import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Typography,
  Stack,
  FormControl,
  InputLabel,
} from "@mui/material";

import { useAppSelector } from "Redux/store";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const AssignPartner: React.FC<{ setPartnerId: (id: string) => void }> = ({
  setPartnerId,
}) => {


  const [partner, setPartner] = useState<any>(null)
  const [value, setValue] = useState('');

  const handleDelete = () => {
    setPartner(null)
    setPartnerId('')
    setValue('')
  }

  const fetchingPartnersWithThun = useAppSelector(state => state.partnersAllOffers);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    setPartnerId(event.target.value);
  }


  return (
    <Paper
      sx={{
        padding: "20px",
      }}
      component={Stack}
      spacing={2}
    >
      <Typography textAlign={"center"}>აირჩიეთ პარტნიორი</Typography>

      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">partner</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="partner"
            fullWidth
            value={value}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {fetchingPartnersWithThun.partnersAllOffers.map(partner => <MenuItem value={partner.id} onClick={() => { setPartner(partner); setPartnerId(partner.id) }}>{partner.firstName} {partner.lastName}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      <Stack>
        {!!partner ? (
          <Stack spacing={2}>
            <Paper
              sx={{
                boxSizing: "border-box",
                padding: "10px",
                backgroundColor: "#aababf38",
                position: "relative",
              }}
              component={Stack}
              spacing={1}
            >
              <Typography sx={{ "& span": { fontWeight: "bold" } }}>
                <span>ნომერი:</span> {partner.mobileNumber}
              </Typography>
              <Typography sx={{ "& span": { fontWeight: "bold" } }}>
                {" "}
                <span>სახელი, გვარი:</span> {partner.firstName}{" "}
                {partner.lastName}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                sx={{ cursor: "pointer", width: "fit-content" }}
                onClick={() => handleDelete()}
              >
                წაშლა
              </Button>
            </Paper>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "12px", margin: "5px 0" }}>
            no partners chosen
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default AssignPartner;
