import { Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { DeleteMakeWarning, EditMakeContent, MakeModelsContent } from "Components/AdminComponents/GarageComponents";
import AddCarBrand from "Components/AdminComponents/GarageComponents/AddCarBrand";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearSingleCar, getSingleCarThunk } from "Redux/slices/singleCarSlice";
import { useAppSelector } from "Redux/store";


interface IProps {
    componentName:string
}

const GarageModal:FC<IProps> = ({componentName}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const {id} = useParams();
    const carMake = useAppSelector(state=>state.singleCar.singleCar);
    const loading = useAppSelector(state=>state.singleCar.loading);
    const handleClose = () => {
        dispatch(clearSingleCar())
        navigate('/admin/cars');
        
    }

    useEffect(()=>{
       if(typeof id === 'string') {
        dispatch(getSingleCarThunk(Number(id)))
    }
    },[])

    const returnComponent = () => {
        switch(componentName){
            case 'makeModels':
                return <MakeModelsContent carMake={carMake} loading={loading}/>
            case 'deleteMake':
                return <DeleteMakeWarning/>
            case 'editMake':
                return <EditMakeContent carMake={carMake} loadingMake={loading}/>
            case 'addMake':
                return <AddCarBrand/>        
            default:
                 return <p>no content</p>    
        }
    }

    return ( <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <Box sx={{padding:'20px'}}>
        {returnComponent()}
        </Box>
       </Dialog>  );
}
 
export default GarageModal;